import { Link as RouterLink, Outlet, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// components
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader } from '../appRedux/actions/common';
import Logo from '../components/Logo';
import Snackbars from '../components/Snackbars';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader, accessToken, authMember } = useSelector((state) => ({
    loader: state.common.loader,
    accessToken: state.auth.accessToken,
    authMember: state.auth.authMember
  }));
  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    if (loader) dispatch(hideLoader());
    if (accessToken && accessToken !== null) navigate('/dashboard/app', { replace: true });
  }, [accessToken]);
  return (
    <>
      <HeaderStyle>{/* <Snackbars key={Math.random()} /> */}</HeaderStyle>
      <Outlet />
    </>
  );
}
