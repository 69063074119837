module.exports = {
  siteName: 'Play GGPL',
  copyright: 'Copyright © All rights reserved |  ',
  logoPath: '/image/logo.png',
  apiPrefix: `${'http://admin.transferitnow.com'}`,
  socketURL: `${'http://admin.transferitnow.com'}`,
  reportDownloadUrl: `${'http://admin.transferitnow.com'}/reports`,
  uploadMediaUrl: `${'http://admin.transferitnow.com'}/media`,
  apiURL: `${'http://admin.transferitnow.com'}/api`
  // apiPrefix: `${'http://localhost:9010'}`,
  // socketURL: `${'http://localhost:9010'}`,
  // reportDownloadUrl: `${'http://localhost:9010'}/reports`,
  // uploadMediaUrl: `${'http://localhost:9010'}/media`,
  // apiURL: `${'http://localhost:9010'}/api`
};
