import {
  MEMBER_CREATE_SUCCESS,
  MEMBER_LIST,
  MEMBER_LIST_SUCCESS,
  MEMBER_INFO_SUCCESS,
  MEMBER_FILTER_HANDLING,
  MEMBER_SEARCH_NAME,
  MEMBER_UPDATE_SUCCESS
} from '../actionTypes/member';

import { COLUMN_HANDLING } from '../../_mocks_/member';

const INIT_STATE = {
  members: [],
  memberList: [],
  isSuccess: false,
  memberInfo: {},
  queryInfo: {
    page: 0,
    limit: 10
  },
  totalRecords: 0,
  page: 0,
  columnHandling: COLUMN_HANDLING,
  searchName: '',
  searchStatus: false,
  memberType: 'Activated',
  isUpdate: false,
  isMember: true
};

function memberReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case MEMBER_LIST: {
      console.log({ payload });
      return {
        ...state,
        members: payload,
        isMember: false
      };
    }
    case MEMBER_LIST_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        memberList: payload.data,
        queryInfo: {
          ...payload.queryInfo
        },
        totalRecords: payload.totalRecords,
        isSuccess: false
        // page: payload.queryInfo.page || 0
      };
    }

    case MEMBER_CREATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        memberList: [payload.data, ...state.memberList],
        isSuccess: true
      };
    }

    case MEMBER_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        memberList: state.memberList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        memberInfo: payload.data,
        isSuccess: true,
        isUpdate: true
      };
    }

    case MEMBER_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        memberInfo: payload.data
        // isSuccess: true
      };
    }
    case MEMBER_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        memberType: payload.memberType
      };
    }
    case MEMBER_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit }
      };
    }

    default:
      return state;
  }
}

export default memberReducer;
