import {
  IMAGE_GALLERY_CREATE_SUCCESS,
  IMAGE_GALLERY_LIST_SUCCESS,
  IMAGE_GALLERY_INFO_SUCCESS,
  IMAGE_GALLERY_UPDATE_SUCCESS,
  IMAGE_GALLERY_DELETE_SUCCESS,
  IMAGE_GALLERY_FILTER_HANDLING,
  IMAGE_GALLERY_SEARCH_NAME,
  IMAGE_GALLERY_ACTION_SUCCESS,
  IMAGE_GALLERY_ALL_LIST,
  IMAGE_GALLERY_CATEGARY
} from '../actionTypes/imageGallery';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';

import { authMemberSignOut } from './auth';
import axiosHttp from '../services/axiosCommon';

export const getGalleryCategory = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/image-gallery/categary', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: IMAGE_GALLERY_CATEGARY,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getGalleryList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  const { listAll } = queryInfo;
  try {
    const result = await axiosHttp.get('/image-gallery', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      if (data.status === 200) {
        dispatch({
          type: IMAGE_GALLERY_LIST_SUCCESS,
          payload: { ...data, queryInfo }
        });
      }
    } else if (data.listAll) {
      dispatch({
        type: IMAGE_GALLERY_ALL_LIST,
        payload: { ...data }
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/image-gallery/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: IMAGE_GALLERY_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const getGalleryInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/image-gallery', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: IMAGE_GALLERY_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createGallery = (infoData, galleryType, associateId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.post(`/image-gallery/${galleryType}/${associateId}`, infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/image-gallery/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: IMAGE_GALLERY_CREATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateGalleryWithFile =
  (infoData, galleryType, associateId, imageId) => async (dispatch) => {
    dispatch({
      type: ON_SHOW_LOADER
    });
    console.log({ infoData }, '------------');
    // url=imageId? `/image-gallery/${galleryType}/${associateId}/${imageId}`:`/image-gallery/${galleryType}/${associateId}/${imageId}`
    try {
      const result = await axiosHttp.put(
        `/image-gallery/${galleryType}/${associateId}/${imageId}`,
        infoData
      );
      const { data } = result;
      dispatch({
        type: ON_HIDE_LOADER
      });
      if (data.error) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: '/settings/image-gallery/list'
        });
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
        dispatch({
          type: IMAGE_GALLERY_UPDATE_SUCCESS,
          payload: data
        });
      }

      return Promise.resolve(data);
    } catch (err) {
      const { response } = err;
      if (response && response.status === 401) {
        dispatch(
          authMemberSignOut({
            changeMessage: true,
            payload: {
              memberMessage: response.data.memberMessage,
              status: response.status,
              error: true
            }
          })
        );
      } else {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
        });
      }
      return Promise.reject(err);
    }
  };

export const updateGallery = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put(`/image-gallery`, infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/image-gallery/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: IMAGE_GALLERY_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const actionGallery = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  const { queryInfo } = infoData;
  try {
    const result = await axiosHttp.put('/image-gallery/action', {
      ...infoData,
      queryInfo: undefined
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/image-gallery/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch(getGalleryList({ ...queryInfo }));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteGallery = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/image-gallery', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/image-gallery/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: IMAGE_GALLERY_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: IMAGE_GALLERY_SEARCH_NAME,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: IMAGE_GALLERY_FILTER_HANDLING,
    payload: info
  });
};
