export const CONTACT_DETAILS_CREATE = 'CONTACT_DETAILS_CREATE';
export const CONTACT_DETAILS_CREATE_SUCCESS = 'CONTACT_DETAILS_CREATE_SUCCESS';
export const CONTACT_DETAILS_CREATE_ERROR = 'CONTACT_DETAILS_CREATE_ERROR';
export const CONTACT_DETAILS_LIST = 'CONTACT_DETAILS_LIST';
export const CONTACT_DETAILS_LIST_SUCCESS = 'CONTACT_DETAILS_LIST_SUCCESS';
export const CONTACT_DETAILS_LIST_ERROR = 'CONTACT_DETAILS_LIST_ERROR';
export const CONTACT_DETAILS_INFO = 'CONTACT_DETAILS_INFO';
export const CONTACT_DETAILS_INFO_SUCCESS = 'CONTACT_DETAILS_INFO_SUCCESS';
export const CONTACT_DETAILS_INFO_ERROR = 'CONTACT_DETAILS_INFO_ERROR';
export const CONTACT_DETAILS_UPDATE_SUCCESS = 'CONTACT_DETAILS_UPDATE_SUCCESS';
export const CONTACT_DETAILS_UPDATE_ERROR = 'CONTACT_DETAILS_UPDATE_ERROR';
export const CONTACT_DETAILS_DELETE_SUCCESS = 'CONTACT_DETAILS_DELETE_SUCCESS';
export const CONTACT_DETAILS_FILTER_HANDLING = 'CONTACT_DETAILS_FILTER_HANDLING';
export const CONTACT_DETAILS_SEARCH_NAME = 'CONTACT_DETAILS_SEARCH_NAME';
