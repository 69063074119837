import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  ListItemText,
  Drawer,
  Typography,
  Avatar,
  ListItem,
  ListItemIcon
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/arrow-back-fill';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import account from '../../_mocks_/account';
import { apiURL } from '../../utils/config';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body1,
    height: 54,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    boxShadow: theme.customShadows.z24,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const location = useLocation();
  const { pathname } = location;
  // console.log({ pathname, location, path: window.location.href });
  // console.log({ location }, window.location.host.split('.')[0]);
  const { authMember, accessLevel } = useSelector((state) => ({
    authMember: state.auth.authMember,
    accessLevel: state.auth.authMember ? state.auth.authMember.accessLevel : 1
  }));
  console.log({ sidebarConfig, accessLevel });
  let navConfig = sidebarConfig.filter((item) => item.accessLevel.includes(accessLevel));
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    navConfig = sidebarConfig.filter((item) => item.accessLevel.includes(accessLevel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, accessLevel]);

  let role = 'Super Admin';
  if (accessLevel === 1) {
    role = 'Super Admin';
  } else if (accessLevel === 2) {
    role = 'Admin';
  } else if (accessLevel === 3) {
    role = 'User';
  } else {
    role = 'User';
  }

  console.log({ navConfig, accessLevel });
  const renderContent = (
    <>
      <Box sx={{ px: 2.8, py: 0.2 }}>
        <Box component={RouterLink} to="/dashboard/app" sx={{ display: 'inline-flex' }}>
          <Logo sx={{}} />
        </Box>
      </Box>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
        }}
      >
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="/profile/view">
            <AccountStyle>
              <Avatar
                src={`${authMember.profileDpUrl ? authMember.profileDpUrl : authMember.name}`}
                alt={authMember && authMember.name}
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {authMember.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {role}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>

        <ListItemStyle component={RouterLink} to={'/dashboard/app'}>
          <ListItemIcon>
            <Icon icon={pieChart2Fill} width={22} height={22} />
          </ListItemIcon>
          <ListItemText disableTypography primary={'Dashboard'} />
          {/* {info && info} */}
        </ListItemStyle>
        {/* <ListItemStyle component={RouterLink} to={'/settings'}>
          <ListItemIcon>
            <Icon icon={pieChart2Fill} width={22} height={22} />
          </ListItemIcon>
          <ListItemText disableTypography primary={'Dashboard'} />
          
        </ListItemStyle> */}
        <NavSection navConfig={navConfig} />

        <Box sx={{ flexGrow: 1 }} />

        {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_rocket.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button
            fullWidth
            href="https://material-ui.com/store/items/minimal-dashboard/"
            target="_blank"
            variant="contained"
          >
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
