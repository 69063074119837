import React, { useState } from 'react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Card, Stack, Container, Typography, Grid } from '@material-ui/core';
// components
import Page from '../../components/Page';

import { FormView, ImageUpload } from '../../components/_user';
// import { Image } from '../../components/_admin';
// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3)
}));
const StyleText = styled('p')(({ theme }) => ({
  margin: '0px auto 16px',
  fontWeight: 400,
  fontSize: '0.9rem',
  lineHeight: 1.5,
  color: theme.palette.grey[500]
}));
// ----------------------------------------------------------------------

export default function Create() {
  return (
    <Page title="User | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create New User
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={5} md={4}>
            <CardStyleImage>
              <ImageUpload />
              <SpanStyle>
                Allowed *.jpeg, *.jpg, *.png, *.gif
                <br /> Max Size of 3MB
              </SpanStyle>
            </CardStyleImage>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <CardStyle>
              <h4>Registration User</h4>
              <StyleText />
              {/* <StyleText>
                After Successful registration, you will be redirected to a page where you will
                assign the New User A Unit / Units.
              </StyleText> */}
              <FormView {...{ isUpdate: false }} />
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
