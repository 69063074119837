import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@iconify/react';
import CloseIcon from '@iconify/icons-ant-design/close-outlined';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon icon={CloseIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

CustomizedDialogs.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.object
};

export default function CustomizedDialogs({ children }) {
  console.log({ children });
  const { props } = children;
  const { title, visible, footer, onCancel } = props;
  return (
    <div>
      <Dialog onClose={onCancel} aria-labelledby="customized-dialog-title" open={visible}>
        <DialogTitle id="customized-dialog-title" onClose={onCancel}>
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {footer && (
          <DialogActions>
            <Button autoFocus onClick={onCancel} color="primary">
              Save changes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

// import React from 'react';
// // import { withStyles } from '@material-ui/core/styles';
// import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
// import IconButton from '@material-ui/core/IconButton';
// // import CloseIcon from '@material-ui/';
// import CloseIcon from '@iconify/icons-ant-design/close-outlined';
// import Typography from '@material-ui/core/Typography';

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2)
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500]
//   }
// });

// const MuiDialogTitleStyle = styled(MuiDialogTitle)(({ theme }) => ({
//   margin: 0,
//   padding: theme.spacing(2)
// }));

// const IconButtonStyle = styled(IconButton)(({ theme }) => ({
//   position: 'absolute',
//   right: theme.spacing(1),
//   top: theme.spacing(1),
//   color: theme.palette.grey[500]
// }));
// const DialogTitle = styled(styles)((props) => {
//   console.log({ props });
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitleStyle disableTypography>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButtonStyle aria-label="close" onClick={onClose}>
//           {/* <CloseIcon /> */}
//         </IconButtonStyle>
//       ) : null}
//     </MuiDialogTitleStyle>
//   );
// });

// const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
//   padding: theme.spacing(2)
// }));
// // const DialogContent = styled((theme) => ({
// //   root: {
// //     padding: theme.spacing(2)
// //   }
// // }))(MuiDialogContent);

// const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
//   margin: 0,
//   padding: theme.spacing(1)
// }));
// // const DialogActions = styled((theme) => ({
// //   root: {
// //     margin: 0,
// //     padding: theme.spacing(1)
// //   }
// // }))(MuiDialogActions);

// export default function CustomizedDialogs(propsInfo) {
//   console.log({ propsInfo });
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Open dialog
//       </Button>
//       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
//         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
//           Modal title
//         </DialogTitle>
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
//             in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
//           </Typography>
//           <Typography gutterBottom>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
//             lacus vel augue laoreet rutrum faucibus dolor auctor.
//           </Typography>
//           <Typography gutterBottom>
//             Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
//             scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
//             auctor fringilla.
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose} color="primary">
//             Save changes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
