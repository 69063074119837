//   column_Handling;
export const COLUMN_HANDLING = [
  {
    id: 'Name',
    key: 'name',
    label: 'Name',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  { id: 'Email', key: 'email', label: 'Email', align: 'left', visibility: true, isFilter: true },

  {
    id: 'CompanyName',
    key: 'companyName',
    label: 'Company Name',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'ContactNumber',
    key: 'contactNumber',
    label: 'Contact Number',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Status',
    key: 'active',
    label: 'Status',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'CreatedAt',
    key: 'createdAt',
    label: 'Created At',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];
