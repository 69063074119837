import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon } from '@material-ui/core';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);
const ListSubItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};
function SubChildrenItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, multiple } = item;
  const [subOpen, setSubOpen] = useState(isActiveRoot);

  const handleSubOpen = () => {
    setSubOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  return (
    <>
      <ListSubItemStyle
        onClick={handleSubOpen}
        // sx={{
        //   ...(isActiveRoot && activeRootStyle)
        //   // paddingLeft: '20px'
        // }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
        <Box
          component={Icon}
          icon={subOpen ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListSubItemStyle>

      <Collapse in={subOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((item) => {
            const { title, path } = item;
            const isActiveSub = active(path);

            return (
              <ListSubItemStyle
                key={title}
                component={RouterLink}
                to={path}
                sx={{
                  ...(isActiveSub && activeSubStyle)
                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                      })
                    }}
                  />
                </ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
              </ListSubItemStyle>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

function ChildrenItem({ isActiveRoot, activeRootStyle, item, active, activeSubStyle }) {
  const { title, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItemStyle
        onClick={handleOpen}
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
        <Box
          component={Icon}
          icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItemStyle>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((item) => {
            const { title, path, children } = item;
            const isActiveSub = active(path);

            if (children) {
              return <SubChildrenItem item={item} active={active} />;
            }
            return (
              <ListItemStyle
                key={title}
                component={RouterLink}
                to={path}
                sx={{
                  ...(isActiveSub && activeSubStyle)
                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                      })
                    }}
                  />
                </ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
              </ListItemStyle>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

function NavItem({ item, active }) {
  console.log({ item });
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <ChildrenItem
        isActiveRoot={isActiveRoot}
        activeRootStyle={activeRootStyle}
        item={item}
        active={active}
        activeSubStyle={activeSubStyle}
      />
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  console.log({ navConfig });
  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
