import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useFormik, Form, FormikProvider } from 'formik';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import arrowBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import infoFill from '@iconify/icons-eva/info-fill';
// material
import {
  Link,
  Stack,
  FormHelperText,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { codeSend, getTempToken, setNewPassword } from '../../../appRedux/actions/auth';
import { showLoader } from '../../../appRedux/actions/common';
// import { Button } from 'antd';

// ----------------------------------------------------------------------

const ClickButton = styled('b')(({ theme }) => ({
  paddingLeft: '10px',
  color: theme.palette.primary.main,

  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  '&:hover': {
    // color: theme.palette.primary.main,
    backgroundColor: 'unset',
    textDecoration: 'underline'
  }
}));

const SpanStyle = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // -webkit-box-align: center;
  alignItems: 'center',
  color: 'rgb(99, 115, 129)',
  lineHeight: 1.5,
  fontSize: '0.75rem',
  fontWeight: 400,
  textAlign: 'left',
  margin: '4px 5px 0px'
}));
const INITIAL_STATE = {
  code: '',
  password: '',
  confirmNewPassword: ''
};
export default function VerifySetNewPasswordForm({ subDomain }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  console.log({ subDomain });

  const [showPassword, setShowPassword] = useState(false);
  const { tempToken, type } = params;
  const { verifyType, code, loader, email } = useSelector((state) => ({
    verifyType: state.auth.verifyType,
    loader: state.common.loader,
    email: state.auth.email,
    code: state.auth.code
  }));
  useEffect(() => {
    if (!tempToken) {
      navigate('/login', { replace: true });
    } else {
      dispatch(getTempToken(tempToken, subDomain));
    }
    // if (code)
    //   navigate(`/request-new-password-set/${code}/${tempToken}`, {
    //     replace: true
    //   });
  }, [tempToken, code]);

  const changeEmail = () => {
    // /request-password-reset
    // dispatch(tokenRemove());
    navigate('/forgot-password', { replace: true });
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const FormSchema = Yup.object().shape({
    code: Yup.string().min(6, 'Invalid code.').required('Code is required.'),
    password: Yup.string()
      .required('Please enter your password.')
      .min(6, 'Your password is too short.'),
    confirmNewPassword: Yup.string()
      .required('Please confirm password your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      dispatch(showLoader());
      dispatch(
        setNewPassword(
          {
            ...data,
            confirmNewPassword: undefined,
            tempToken,
            type: verifyType
          },
          subDomain
        )
      );
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;
  console.log({ errors, touched, values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <MuiOtpInput
            sx={{ gap: 1 }}
            // onComplete={(finalValue) => {}}
            onComplete={(finalValue) => {
              const event = {
                target: {
                  name: 'code',
                  value: finalValue
                }
              };
              handleChange(event);
            }}
            value={values.code}
            onChange={(newValue) => {
              // handleChangeValue(value);
              const event = {
                target: {
                  name: 'code',
                  value: newValue
                }
              };
              handleChange(event);
              handleBlur({ target: { name: 'code' } });
            }}
            length={6}
          />

          <FormHelperText error={Boolean(touched.code && errors.code)}>
            {Boolean(touched.code) && errors.code}
          </FormHelperText>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <SpanStyle>
            <Icon icon={infoFill} height={14} />
            &nbsp; Password must be minimum 6 characters
          </SpanStyle>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm New Password"
            {...getFieldProps('confirmNewPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loader}>
          Verify
        </LoadingButton>
        <Typography sx={{ padding: 2 }}>
          Don’t have a code?
          <ClickButton
            onClick={() =>
              dispatch(
                codeSend(
                  {
                    tempToken,
                    type: verifyType === 'SignUp' ? 'SignUpResendCode' : 'ResendCode'
                  },
                  subDomain
                )
              )
            }
          >
            Resend Code
          </ClickButton>
        </Typography>
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ my: 1 }}
        ></Stack>
        <ButtonStyle
          component={RouterLink}
          variant="subtitle2"
          to="/login"
          startIcon={<Icon icon={arrowBackFill} />}
        >
          Return to sign in
        </ButtonStyle>
      </Form>
    </FormikProvider>
  );
}
