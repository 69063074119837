import { useState } from 'react';
import { Upload, message } from 'antd';
import { Icon } from '@iconify/react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PlusOutlined from '@iconify/icons-ant-design/camera-outlined';
import LoadingOutlined from '@iconify/icons-ant-design/loading-outlined';
import { apiURL } from '../../utils/config';

const IconStyle = styled(Icon)(({ theme }) => ({
  // color: theme.palette.primary.main
}));
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default function Avatar() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const uploadButton = (
    <div className="upload-icon">
      {loading ? (
        <Icon icon={LoadingOutlined} height={24} />
      ) : (
        //
        <Icon icon={PlusOutlined} height={24} />
      )}
      <p style={{}}>Upload photo</p>
    </div>
  );
  const uploadButtonBefore = (
    <div className="upload-icon-before">
      {loading ? (
        <Icon icon={LoadingOutlined} height={24} />
      ) : (
        //
        <Icon icon={PlusOutlined} height={24} />
      )}
      <p style={{}}>Upload photo</p>
    </div>
  );
  return (
    <Upload
      id="imageCircle"
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={`${apiURL}/media`}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <>
          <img src={imageUrl} alt="avatar" />
          {uploadButton}
        </>
      ) : (
        uploadButtonBefore
      )}
      {}
    </Upload>
  );
}
