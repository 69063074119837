export const MEMBER_CREATE = 'MEMBER_CREATE';
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS';
export const MEMBER_CREATE_ERROR = 'MEMBER_CREATE_ERROR';
export const MEMBER_LIST = 'MEMBER_LIST';
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS';
export const MEMBER_LIST_ERROR = 'MEMBER_LIST_ERROR';
export const MEMBER_INFO = 'MEMBER_INFO';
export const MEMBER_INFO_SUCCESS = 'MEMBER_INFO_SUCCESS';
export const MEMBER_INFO_ERROR = 'MEMBER_INFO_ERROR';
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS';
export const MEMBER_UPDATE_ERROR = 'MEMBER_UPDATE_ERROR';
export const MEMBER_DELETE_SUCCESS = 'MEMBER_DELETE_SUCCESS';
export const MEMBER_FILTER_HANDLING = 'MEMBER_FILTER_HANDLING';
export const MEMBER_SEARCH_NAME = 'MEMBER_SEARCH_NAME';
export const MEMBER_ACTION_SUCCESS = 'MEMBER_ACTION_SUCCESS';
