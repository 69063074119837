import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import imageUpload from '@iconify/icons-ic/image';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Fab,
  FormControlLabel,
  Button,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { CountryList } from '../../../utils/constant';
import {
  createGallery,
  updateGallery,
  updateGalleryWithFile
} from '../../../appRedux/actions/imageGallery';
import { showLoader } from '../../../appRedux/actions/common';

import PreviewFile from './previewFile';
import CustomizedDialogs from '../../CustomizedDialogs';
// ----------------------------------------------------------------------

const InputStyle = styled('input')(({ theme }) => ({
  display: 'none'
}));

const FormHelperTextStyle = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 25,
  marginTop: 0,
  marginBottom: 0
}));
const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 0, 0)
}));
const initialValue = {
  title: '',
  altText: '',
  height: '',
  width: '',
  imageType: ''
};

FormView.propTypes = {
  isUpdate: PropTypes.bool
};
const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

function isValidFileType(imageName, imageType) {
  console.log({ imageName, imageType });
  return imageName && validFileExtensions[imageType].indexOf(imageName.split('.').pop()) > -1;
}

function getAllowedExt(type) {
  console.log({ type });
  return validFileExtensions[type].map((e) => `.${e}`).toString();
}
function getAllowedDisplayExt(type) {
  console.log({ type });
  return validFileExtensions[type].map((e) => ` ${e}`).toString();
}

const MAX_FILE_SIZE = 3 * 102400;
export default function FormView({ isUpdate }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, galleryInfo, url, associateId, loader, galleryCategary } = useSelector(
    (state) => ({
      isSuccess: state.imageGallery.isSuccess,
      galleryInfo: state.imageGallery.galleryInfo,
      galleryCategary: state.imageGallery.galleryCategary,
      associateId:
        state.auth.authMember &&
        state.auth.authMember.associateId &&
        state.auth.authMember.associateId._id,

      messageStatus: state.common.messageStatus,
      url: state.common.initURL,
      loader: state.common.loader
    })
  );
  const [previewVisible, setPreviewVisible] = useState(false);
  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  useEffect(() => {
    console.log({ isSuccess, url });
    if (url === '/settings/image-gallery/list' && isSuccess) {
      console.log({ isSuccess, url });
      const timeout = setTimeout(() => {
        console.log({ isSuccess, url });
        navigate(url, { replace: true });
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isUpdate) setActive(galleryInfo.active);
  }, [url, isSuccess, galleryInfo]);

  const [active, setActive] = useState(isUpdate ? galleryInfo.active : true);
  const [tempFile, setTempFile] = useState(isUpdate && galleryInfo ? false : true);
  const Schema = Yup.object().shape({
    title: Yup.string()
      .min(4, 'Title must be at least 4 characters')
      .max(10, 'Title must be at most 10 characters')
      .required('Title is a required.'),
    altText: Yup.string()
      .min(4, 'Alt text must be at least 4 characters')
      .max(10, 'Alt text must be at most 10 characters')
      .required('Alt text is a required.'),
    height: Yup.string(),
    width: Yup.string(),
    imageType: Yup.string().required('Image type is required.'),
    image: tempFile
      ? Yup.mixed()
          .required('Image is required.')
          .test('is-valid-type', 'Not a valid image type.', (value) =>
            isValidFileType(value && value.name.toLowerCase(), 'image')
          )
          .test(
            'is-valid-size',
            'Max allowed size is 300KB.',
            (value) => value && value.size <= MAX_FILE_SIZE
          )
      : Yup.string()
  });
  const initValues = galleryInfo && galleryInfo._id && isUpdate ? galleryInfo : initialValue;

  console.log({ galleryInfo, initValues, associateId });
  const formik = useFormik({
    enableReinitialize: isUpdate || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      console.log({ data, isUpdate }, '------------');
      const bodyFormData = new FormData();

      dispach(showLoader());
      if (isUpdate) {
        if (tempFile) {
          Object.entries(data).forEach(([key, value]) => {
            if (key !== '_id') {
              bodyFormData.append(key, value);
            } else {
              bodyFormData.append('galleryId', value);
            }
          });
          const imageLink = galleryInfo.imageUrl.split('/').pop();
          const imageId = imageLink.split('.')[0];
          console.log({ imageId });
          // imageId
          dispach(updateGalleryWithFile(bodyFormData, data.imageType, associateId, imageId));
        } else {
          dispach(
            updateGallery({
              ...data,
              _id: undefined,
              galleryId: galleryInfo._id,
              active
            })
          );
        }
      } else {
        Object.entries(data).forEach(([key, value]) => {
          bodyFormData.append(key, value);
        });
        dispach(createGallery(bodyFormData, data.imageType, associateId));
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  console.log({ errors, touched, values });
  const renderUploadButton = (formik, errors, inputName) => {
    console.log({ inputName });
    const allowedExts = getAllowedExt(inputName);
    console.log({ errors }, errors && Boolean(errors.image));
    return (
      <>
        <InputStyle
          id={`button-upload-${inputName}`}
          name={inputName}
          // type="image"
          type="file"
          accept={allowedExts}
          onChange={(event) => {
            // console.log({ event });
            setTempFile(true);
            formik.setFieldValue(inputName, event.currentTarget.files[0]);
          }}
        />
        <label htmlFor={`button-upload-${inputName}`}>
          <Button
            component="span"
            variant="outlined"
            startIcon={<Icon icon={imageUpload} />}
            sx={{ margin: 3, marginTop: 0, marginBottom: 0 }}
            color={errors && Boolean(errors.image) ? 'error' : 'primary'}
          >
            Upload {inputName}
          </Button>
          <Typography className="ext">[{getAllowedDisplayExt(inputName)}]</Typography>
        </label>
      </>
    );
  };
  console.log({ tempFile });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  label="Title"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  type="altText"
                  label="Alt Text"
                  {...getFieldProps('altText')}
                  error={Boolean(touched.altText && errors.altText)}
                  helperText={touched.altText && errors.altText}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  type="height"
                  label="Height"
                  {...getFieldProps('height')}
                  error={Boolean(touched.height && errors.height)}
                  helperText={touched.height && errors.height}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Width"
                  {...getFieldProps('width')}
                  error={Boolean(touched.width && errors.width)}
                  helperText={touched.width && errors.width}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">Image Type</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Image Type"
                    {...getFieldProps('imageType')}
                    error={Boolean(touched.imageType && errors.imageType)}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Banner'} sx={{ textTransform: 'capitalize' }}>
                      Banner
                    </MenuItem>
                    <MenuItem value={'Logo'} sx={{ textTransform: 'capitalize' }}>
                      Logo
                    </MenuItem>
                  </Select>
                  <FormHelperTextStyle>{touched.imageType && errors.imageType}</FormHelperTextStyle>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth variant="outlined">
                  {renderUploadButton(formik, errors, 'image')}
                  <Typography sx={{ paddingTop: 0.5, fontSize: '12px' }}>
                    {values.image
                      ? values.image.name
                      : `${
                          galleryInfo && galleryInfo._id
                            ? `${galleryInfo.title}.${galleryInfo.imageUrl.split('.').pop()}`
                            : ``
                        }`}
                  </Typography>
                  {tempFile && (
                    <FormHelperTextStyle sx={{ color: (theme) => theme.palette.error.main }}>
                      {Boolean(errors.image) && errors.image}
                    </FormHelperTextStyle>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <ButtonStyle
                  component="span"
                  variant="outlined"
                  // type='button'

                  onClick={() => handlePreview()}
                  startIcon={<Icon icon={eyeFilled} />}
                  disabled={
                    !(
                      (values && values.image && values.image.name !== '') ||
                      (isUpdate && galleryInfo)
                    )
                  }
                >
                  Preview image
                </ButtonStyle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {previewVisible ? (
          <CustomizedDialogs>
            <PreviewFile
              className={{ margin: 'auto' }}
              width={'auto'}
              height={'auto'}
              file={values.image}
              galleryInfo={galleryInfo}
              tempFile={tempFile}
              visible={previewVisible}
              title={
                values.image
                  ? values.image.name
                  : `${
                      galleryInfo && galleryInfo._id
                        ? `${galleryInfo.title}.${galleryInfo.imageUrl.split('.').pop()}`
                        : ``
                    }`
              }
              // footer={null}
              onCancel={() => handleCancel()}
            />
          </CustomizedDialogs>
        ) : null}

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {isUpdate ? 'Update Gallery' : ' Create Gallery'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
