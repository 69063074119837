import { AUTH_USER_UPDATE, SIGNIN_USER_SUCCESS } from '../actionTypes/auth';
import { AUTH_INIT_STATE } from './auth';

export const INIT_STATE = {
  authMember: AUTH_INIT_STATE.authMember
};

function AuthReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNIN_USER_SUCCESS: {
      state.initURL = '/';
      return {
        ...state,
        authMember: payload.data
      };
    }
    case AUTH_USER_UPDATE: {
      return {
        ...state,
        authMember: payload
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
