import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SettingsLayout from './layouts/settings';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// pages
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import VerifyCode from './pages/VerifyCode';
import NewPasswordSet from './pages/NewPasswordSet';
import Register from './pages/Register';
import DashboardApp from './pages/Dashboard/app';

// import AuthMember from './pages/AuthMember';

import MemberList from './pages/Member/list';
import MemberCreate from './pages/Member/create';
import MemberUpdate from './pages/Member/update';
import MemberView from './pages/Member/view';

import ProfileView from './pages/Profile/view';
import ResetPassword from './pages/Profile/resetPassword';
import NotFound from './pages/Page404';

import AssociateList from './pages/Associate/list';
import AssociateCreate from './pages/Associate/create';
import AssociateUpdate from './pages/Associate/update';

import UserList from './pages/User/list';
import UserCreate from './pages/User/create';
import UserUpdate from './pages/User/update';
import UserInfo from './pages/User/view';

import TransferList from './pages/TransferHistory/list';
import TransferDetails from './pages/TransferHistory/details';

import ContactList from './pages/Contact/list';
import ContactUpdate from './pages/Contact/update';

import ContactDetailsList from './pages/ContactDetails/list';

import EmailTemplateList from './pages/Settings/EmailTemplate/list';
import EmailTemplateCreate from './pages/Settings/EmailTemplate/create';
import EmailTemplateUpdate from './pages/Settings/EmailTemplate/update';
import EmailTemplateView from './pages/Settings/EmailTemplate/view';
import ImageGalleryList from './pages/Settings/ImageGallery/list';
import ImageGalleryCreate from './pages/Settings/ImageGallery/create';
import ImageGalleryUpdate from './pages/Settings/ImageGallery/update';
import ImageGalleryView from './pages/Settings/ImageGallery/view';
import EmailSetting from './pages/Settings/EmailSetting';
import VerifyNewPasswordSet from './pages/VerifyNewPasswordSet';
// import { ResetPassword } from './components/_profile';

// ----------------------------------------------------------------------
const ROUTE_MENU = [
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6, 15],
    children: [
      { path: '', element: <Navigate to="/dashboard/app" replace /> },
      { path: 'app', element: <DashboardApp /> }
    ]
  },
  {
    path: '/associate',
    element: <DashboardLayout />,
    accessLevel: [1],
    children: [
      { path: 'list', element: <AssociateList /> },
      { path: 'new', element: <AssociateCreate /> },
      { path: ':associateId/update', element: <AssociateUpdate /> },
      { path: ':associateId/info', element: <AssociateUpdate /> }
    ]
  },
  {
    path: '/contact',
    element: <DashboardLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: 'list', element: <ContactList /> },
      { path: ':contactId/list', element: <ContactDetailsList /> }
    ]
  },
  {
    path: '/contact',
    element: <DashboardLayout />,
    accessLevel: [15],
    children: [
      { path: 'list', element: <ContactList /> },
      { path: ':contactId/info', element: <ContactUpdate /> }
    ]
  },
  {
    path: '/user',
    element: <DashboardLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: 'list', element: <UserList /> },
      { path: 'new', element: <UserCreate /> },
      { path: ':userId/update', element: <UserUpdate /> },
      { path: ':userId/info', element: <UserInfo /> }
    ]
  },
  {
    path: '/transfer',
    element: <DashboardLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: ':userId/activity', element: <TransferList /> },
      { path: ':transferId/details', element: <TransferDetails /> }
    ]
  },
  {
    path: '/member',
    element: <DashboardLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: 'list', element: <MemberList /> },
      { path: 'new', element: <MemberCreate /> },
      { path: ':memberId/update', element: <MemberUpdate /> },
      { path: ':memberId/info', element: <MemberView /> }
    ]
  },
  {
    path: '/settings',
    element: <SettingsLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6]
  },
  {
    path: 'settings/email-template',
    element: <SettingsLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: 'list', element: <EmailTemplateList /> },
      { path: 'new', element: <EmailTemplateCreate /> },
      { path: ':templateId/update', element: <EmailTemplateUpdate /> },
      { path: ':templateId/info', element: <EmailTemplateView /> }
    ]
  },
  {
    path: 'settings/image-gallery',
    element: <SettingsLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [
      { path: 'list', element: <ImageGalleryList /> },
      { path: 'new', element: <ImageGalleryCreate /> },
      { path: ':galleryId/update', element: <ImageGalleryUpdate /> },
      { path: ':galleryId/info', element: <ImageGalleryView /> }
    ]
  },
  {
    path: 'settings/email-setting',
    element: <SettingsLayout />,
    accessLevel: [1, 2, 3, 4, 5, 6],
    children: [{ path: '', element: <EmailSetting /> }]
  }
];

export default function Router() {
  const [level, setLevel] = useState();
  const { accessToken, routeLevel } = useSelector((state) => ({
    routeLevel: state.auth.routeLevel,
    accessToken: state.auth.accessToken
  }));
  //

  // console.log({ routeLevel }, 'accessLevel---------------1');
  const FILTER_ROUTE = ROUTE_MENU.filter((item) => item.accessLevel.includes(routeLevel));

  const COMMON_ROUTE = [
    {
      path: '/profile',
      element: <DashboardLayout />,
      children: [
        { path: 'view', element: <ProfileView /> },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },

        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        // {
        //   path: 'verify-code/:tempToken',
        //   element: <VerifyCode />
        // },
        {
          path: 'verify-code/:tempToken',
          element: <VerifyNewPasswordSet />
        },
        {
          path: 'request-new-password-set/:code/:tempToken',
          element: <NewPasswordSet />
        },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        {
          path: '/',
          element: <Navigate to={accessToken !== null ? '/dashboard/app' : '/login'} replace />
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  return useRoutes(FILTER_ROUTE.concat(COMMON_ROUTE));
}
