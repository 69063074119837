import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import fileUpload from '@iconify/icons-ic/file-upload';
import addImage from '@iconify/icons-ic/image';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Fab,
  FormControlLabel,
  Button,
  Avatar,
  Typography,
  ListItemAvatar,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { CountryList } from '../../../utils/constant';
import {
  createTemplate,
  updateTemplate,
  updateTemplateWithFile
} from '../../../appRedux/actions/emailTemplate';
import { showLoader } from '../../../appRedux/actions/common';

import PreviewFile from './previewFile';
import { apiURL } from '../../../utils/config';
// ----------------------------------------------------------------------
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  icon: {
    margin: theme.spacing.unit * 2
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: theme.color.text.error.main
    }
  },
  cardHeader: {
    textalign: 'center',
    align: 'center',
    backgroundColor: 'white'
  },
  input: {
    display: 'none'
  },
  title: {
    color: theme.color.text.primery.main,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    align: 'center'
  },
  button: {
    color: theme.color.text.primery.main,
    margin: 10
  },
  secondaryButton: {
    color: 'gray',
    margin: 10
  },
  typography: {
    margin: theme.spacing.unit * 2,
    backgroundColor: 'default'
  },

  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  searchInput: {
    marginLeft: 8,
    flex: 1
  },
  searchIconButton: {
    padding: 10
  },
  searchDivider: {
    width: 1,
    height: 28,
    margin: 4
  }
});
const InputStyle = styled('input')(({ theme }) => ({
  display: 'none'
}));

const FormHelperTextStyle = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 10
}));
const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 0, 0)
}));
const initialValue = {
  name: '',
  senderEmail: '',
  senderName: '',
  subject: '',
  templateType: '',
  templateSubType: '',
  file: '',
  imageType: '',
  galleryId: ''
};

FormView.propTypes = {
  isUpdate: PropTypes.bool
};

const validFileExtensions = {
  file: ['html']
};

function isValidFileType(fileName, fileType) {
  console.log({ fileName, fileType });
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

function getAllowedExt(type) {
  console.log({ type });
  return validFileExtensions[type].map((e) => `.${e}`).toString();
}

const MAX_FILE_SIZE = 2 * 102400;
export default function FormView({ isUpdate }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, templateInfo, url, associateId, loader, templateCategary, galleryAllList } =
    useSelector((state) => ({
      isSuccess: state.emailTemplate.isSuccess,
      templateInfo: state.emailTemplate.templateInfo,
      templateCategary: state.emailTemplate.templateCategary,
      galleryAllList: state.imageGallery.galleryAllList,
      associateId:
        state.auth.authMember &&
        state.auth.authMember.associateId &&
        state.auth.authMember.associateId._id,

      messageStatus: state.common.messageStatus,
      url: state.common.initURL,
      loader: state.common.loader
    }));

  useEffect(() => {
    console.log({ isSuccess, url });
    if (url === '/settings/email-template/list' && isSuccess) {
      console.log({ isSuccess, url });
      const timeout = setTimeout(() => {
        console.log({ isSuccess, url });
        navigate(url, { replace: true });
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isUpdate) setActive(templateInfo.active);
  }, [url, isSuccess, templateInfo]);

  const [galleryList, setGalleryList] = useState(
    galleryAllList.filter((info) => info.imageType === templateInfo.imageType)
  );
  const [selectImageType, setSelectImageType] = useState('');
  const [active, setActive] = useState(isUpdate ? templateInfo.active : true);
  const [tempFile, setTempFile] = useState(isUpdate && templateInfo ? false : true);
  const Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Template Name is required.'),
    senderEmail: Yup.string()
      .email('Sender Email must be a valid email address')
      .required('Sender Email is required.'),
    senderName: Yup.string().min(4, 'Too Short!').required('Sender Name is required.'),
    subject: Yup.string().min(8, 'Too Short!').required('Subject is required.'),
    imageType: Yup.string().required('Image type is required.'),
    galleryId: Yup.string().required('Select image is required.'),
    templateType: Yup.string().required('Template type is required.'),
    templateSubType: Yup.string().required('Template sub-type is required.'),
    file: tempFile
      ? Yup.mixed()
          .required('Required')
          .test('is-valid-type', 'Not a valid image type', (value) =>
            isValidFileType(value && value.name.toLowerCase(), 'file')
          )
          .test(
            'is-valid-size',
            'Max allowed size is 100KB',
            (value) => value && value.size <= MAX_FILE_SIZE
          )
      : Yup.string()
  });
  const initValues = templateInfo && templateInfo._id && isUpdate ? templateInfo : initialValue;

  const formik = useFormik({
    enableReinitialize: isUpdate || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      console.log({ data, isUpdate }, '------------');
      const bodyFormData = new FormData();

      dispach(showLoader());
      if (isUpdate) {
        if (tempFile) {
          Object.entries(data).forEach(([key, value]) => {
            if (key !== '_id') {
              bodyFormData.append(key, value);
            } else {
              bodyFormData.append('templateId', value);
            }
          });
          dispach(
            updateTemplateWithFile(
              bodyFormData,
              data.templateType,
              data.templateSubType,
              associateId
            )
          );
        } else {
          dispach(
            updateTemplate({
              ...data,
              _id: undefined,
              templateId: templateInfo._id,
              active
            })
          );
        }
      } else {
        Object.entries(data).forEach(([key, value]) => {
          bodyFormData.append(key, value);
        });
        dispach(createTemplate(bodyFormData, data.templateType, data.templateSubType, associateId));
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  console.log({ errors, touched, values });
  const renderUploadButton = (formik, errors, inputName) => {
    console.log({ inputName });
    const allowedExts = getAllowedExt(inputName);
    return (
      <>
        <InputStyle
          id={`button-${inputName}`}
          name={inputName}
          type="file"
          accept={allowedExts}
          onChange={(event) => {
            setTempFile(true);
            formik.setFieldValue(inputName, event.currentTarget.files[0]);
          }}
        />
        <label htmlFor={`button-${inputName}`}>
          <ButtonStyle component="span" variant="outlined" startIcon={<Icon icon={fileUpload} />}>
            Upload {inputName}
            <span className="ext">[{allowedExts}]</span>
          </ButtonStyle>
        </label>
      </>
    );
  };

  useEffect(() => {
    if (values.imageType !== selectImageType) {
      setGalleryList(galleryAllList.filter((info) => info.imageType === values.imageType));
      setSelectImageType(values.imageType);
      setFieldValue('galleryId', '');
    }
    if (templateInfo && templateInfo._id) {
      setFieldValue('galleryId', templateInfo.galleryId);
    }
  }, [values.imageType, templateInfo]);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              label="Template Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                error={Boolean(touched.templateType && errors.templateType)}
              >
                Template Type
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Template Type"
                {...getFieldProps('templateType')}
                error={Boolean(touched.templateType && errors.templateType)}
                sx={{ textTransform: 'capitalize' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templateCategary &&
                  templateCategary.map((list) => (
                    <MenuItem
                      key={list.templateType}
                      value={list.templateType}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {list.templateType}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperTextStyle>
                {touched.templateType && errors.templateType}
              </FormHelperTextStyle>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                error={Boolean(touched.templateSubType && errors.templateSubType)}
              >
                Template Sub-Type
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Template Sub-Type"
                {...getFieldProps('templateSubType')}
                error={Boolean(touched.templateSubType && errors.templateSubType)}
                sx={{ textTransform: 'capitalize' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templateCategary &&
                  templateCategary.filter((row) => row.templateType === values.templateType)[0] &&
                  templateCategary.filter((row) => row.templateType === values.templateType)[0]
                    .templateSubType &&
                  templateCategary
                    .filter((row) => row.templateType === values.templateType)[0]
                    .templateSubType.map((list) => (
                      <MenuItem key={list} value={list} sx={{ textTransform: 'capitalize' }}>
                        {list}
                      </MenuItem>
                    ))}
              </Select>
              <FormHelperTextStyle>
                {touched.templateSubType && errors.templateSubType}
              </FormHelperTextStyle>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                error={Boolean(touched.imageType && errors.imageType)}
              >
                Image Type
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Image Type"
                {...getFieldProps('imageType')}
                error={Boolean(touched.imageType && errors.imageType)}
                sx={{ textTransform: 'capitalize' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'Banner'} sx={{ textTransform: 'capitalize' }}>
                  {'Banner'}
                </MenuItem>
                <MenuItem value={'Logo'} sx={{ textTransform: 'capitalize' }}>
                  {'Logo'}
                </MenuItem>
              </Select>
              <FormHelperTextStyle>{touched.imageType && errors.imageType}</FormHelperTextStyle>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                id="demo-simple-select-outlined-label"
                error={Boolean(touched.galleryId && errors.galleryId)}
              >
                Select Image{' '}
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Select Image"
                {...getFieldProps('galleryId')}
                error={Boolean(touched.galleryId && errors.galleryId)}
                sx={{ textTransform: 'capitalize' }}
              >
                {galleryList.length ? (
                  galleryList.map((list) => (
                    <MenuItem key={list._id} value={list._id} sx={{ textTransform: 'capitalize' }}>
                      <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                          <Avatar
                            alt={list.title}
                            src={`${apiURL}${list.imageUrl}?v=${list.count}`}
                            variant="square"
                            sx={{ width: 54, height: 22 }}
                          ></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${list.title}`} secondary="" />
                      </ListItem>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
              <FormHelperTextStyle>{touched.galleryId && errors.galleryId}</FormHelperTextStyle>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ButtonStyle
              variant="outlined"
              startIcon={<Icon icon={addImage} />}
              component={RouterLink}
              to="/settings/image-gallery/new"
            >
              Add More Image
            </ButtonStyle>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="senderEmail"
              label="Sender Email"
              {...getFieldProps('senderEmail')}
              error={Boolean(touched.senderEmail && errors.senderEmail)}
              helperText={touched.senderEmail && errors.senderEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="senderName"
              label="Sender Name"
              {...getFieldProps('senderName')}
              error={Boolean(touched.senderName && errors.senderName)}
              helperText={touched.senderName && errors.senderName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              label="Subject"
              {...getFieldProps('subject')}
              error={Boolean(touched.subject && errors.subject)}
              helperText={touched.subject && errors.subject}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              {renderUploadButton(formik, errors, 'file')}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <FormControl fullWidth variant="outlined">
              {values.file || (isUpdate && templateInfo) ? (
                <PreviewFile
                  className={{ margin: 'auto' }}
                  width={50}
                  height={'auto'}
                  file={values.file}
                  templateInfo={templateInfo}
                  isUpdate={isUpdate}
                />
              ) : null}
            </FormControl>
          </Grid>
        </Grid>

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {isUpdate ? 'Update Template' : ' Create Template'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
