import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import fileUpload from '@iconify/icons-ic/file-upload';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  IconButton,
  Button
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { CountryList } from '../../../utils/constant';
import { updateEmailSetting } from '../../../appRedux/actions/emailSetting';
import { showLoader } from '../../../appRedux/actions/common';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 2, 2, 2)
}));
const initialValue = {
  smtpAuthMember: '',
  smtpHost: '',
  smtpPort: '',
  smtpDomain: '',
  smtpPassword: '',
  smtpSecurity: ''
};

FormView.propTypes = {
  isUpdate: PropTypes.bool
};

export default function FormView({ isUpdate }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { isSuccess, templateInfo, url, associateId, loader, templateCategary } = useSelector(
    (state) => ({
      isSuccess: state.emailSetting.isSuccess,
      templateInfo: state.emailSetting.templateInfo,
      templateCategary: state.emailSetting.templateCategary,
      associateId:
        state.auth.authMember &&
        state.auth.authMember.associateId &&
        state.auth.authMember.associateId._id,

      messageStatus: state.common.messageStatus,
      url: state.common.initURL,
      loader: state.common.loader
    })
  );

  const Schema = Yup.object().shape({
    smtpHost: Yup.string().required('SMTP HOST is required.'),
    smtpAuthMember: Yup.string()
      .email('SMTP USER must be a valid email address')
      .required('SMTP USER is required.'),
    smtpDomain: Yup.string()
      .min(4, 'SMTP Authentication Domain too short!')
      .required('SMTP Authentication Domain is required.'),
    smtpPassword: Yup.string()
      .min(4, 'SMTP PASSWORD Too Short!')
      .required('SMTP PASSWORD is required.'),
    smtpSecurity: Yup.string().required('SMTP Security is required.'),
    smtpPort: Yup.number().required('SMTP PORT is required.')
  });
  const initValues = templateInfo && templateInfo._id && isUpdate ? templateInfo : initialValue;

  console.log({ templateInfo, initValues, associateId });
  const formik = useFormik({
    enableReinitialize: isUpdate || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      console.log({ data, isUpdate }, '------------');

      dispach(showLoader());

      dispach(updateEmailSetting(data));
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  console.log({ errors, touched });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="smtpHost"
              label="SMTP HOST"
              {...getFieldProps('smtpHost')}
              error={Boolean(touched.smtpHost && errors.smtpHost)}
              helperText={touched.smtpHost && errors.smtpHost}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="smtpAuthMember"
              label="SMTP USER"
              {...getFieldProps('smtpAuthMember')}
              error={Boolean(touched.smtpAuthMember && errors.smtpAuthMember)}
              helperText={touched.smtpAuthMember && errors.smtpAuthMember}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {/* <TextField
              fullWidth
              type="smtpPassword"
              label="SMTP PASSWORD"
              {...getFieldProps('smtpPassword')}
              error={Boolean(touched.smtpPassword && errors.smtpPassword)}
              helperText={touched.smtpPassword && errors.smtpPassword}
            /> */}

            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="SMTP PASSWORD"
              {...getFieldProps('smtpPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.smtpPassword && errors.smtpPassword)}
              helperText={touched.smtpPassword && errors.smtpPassword}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="smtpPort"
              label="SMTP PORT"
              {...getFieldProps('smtpPort')}
              error={Boolean(touched.smtpPort && errors.smtpPort)}
              helperText={touched.smtpPort && errors.smtpPort}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">SMTP Security</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="SMTP Security"
                {...getFieldProps('smtpSecurity')}
                error={Boolean(touched.smtpSecurity && errors.smtpSecurity)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="SSL">SSL</MenuItem>
                <MenuItem value="TLS">TLS</MenuItem>
              </Select>
              <FormHelperText>{touched.templateType && errors.templateType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              type="smtpDomain"
              label="SMTP Authentication Domain"
              {...getFieldProps('smtpDomain')}
              error={Boolean(touched.smtpDomain && errors.smtpDomain)}
              helperText={touched.smtpDomain && errors.smtpDomain}
            />
          </Grid>
        </GridStyle>

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {templateInfo && templateInfo._id ? 'Update Setting' : ' Create Setting'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
