import React, { useEffect, useState } from 'react';
import { Upload, Alert } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage, showMessageImage } from '../appRedux/actions/common';

import { apiURL } from '../utils/config';
import CustomizedDialogs from './CustomizedDialogs';
// import 'antd/dist/antd.css';
// import './ant-custom.scss';

export default function ImageUploadSingle({
  fileList: list,
  fileInfo,
  setFileList,
  setFileInfo,
  aspect,
  error
}) {
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const state = useSelector(state => state.state)
  let fileList = [];
  if (list && list.length > 0) {
    console.log({ list });
    fileList = list.map((row) => {
      let info = row;
      if (!(row.thumbUrl && row.thumbUrl !== '') && row.url && row.url !== 'undefined') {
        info = { ...row, thumbUrl: apiURL + row.url };
      }
      return info;
    });
  }
  const { accessToken, showMessageStatus, messageStatus, message, imageValidation } = useSelector(
    (state) => ({
      accessToken: state.auth.accessToken,
      loader: state.common.loader,
      showMessageStatus: state.common.showMessage,
      messageStatus: state.common.messageStatus,
      message: state.common.message,
      imageValidation: state.common.imageValidation
      // showMessage: true,
      // messageStatus: payload.error ? "error" : "success",
      // message: payload.authMemberMessage,
    })
  );

  useEffect(() => {
    // dispatch(hideMessageImage());
  }, []);
  const onChange = ({ file, fileList: newFileList }) => {
    console.log({ newFileList, file });
    setFileList(newFileList);
    setFileInfo(file);
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handlePreview = async (file) => {
    console.log({ file });
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.thumbUrl ? file.thumbUrl : file.preview);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    setPreviewVisible(true);
  };
  const handleCancel = () => {
    //   'ant-scrolling-effect';

    document.body.className = document.body.className.replace('ant-scrolling-effect', '');
    //   const elements = document.getElementsByClassName('modal-backdrop');
    //   while (elements.length > 0) elements[0].remove();
    setPreviewImage('');
    setPreviewTitle('');
    setPreviewVisible(false);
  };
  function beforeUploads(file) {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/jeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      setErrorStatus(true);
      setErrorMessage(' You can only upload JPG/PNG file!');
      dispatch(
        showMessageImage({
          authMemberMessage: 'You can only upload JPG/PNG file!',
          status: 404,
          error: true
        })
      );
      return false;
      // message.error("You can only upload JPG/PNG file!");
    }

    console.log('validate--file.size-', file.size / 1024 / 1024);
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      setErrorStatus(true);
      setErrorMessage(' Image must smaller than 3MB!');
      dispatch(
        showMessageImage({
          authMemberMessage: 'Image must smaller than 3MB!',
          status: 404,
          error: true
        })
      );
      return false;
      // message.error("Image must smaller than 2MB!");
    }
    setErrorStatus(false);
    setErrorMessage(' ');
    console.log('validate', isJpgOrPng && isLt2M);
    return isJpgOrPng && isLt2M;
  }
  console.log({ fileInfo, length: fileList.length });
  return (
    <div id="CoverImage">
      {imageValidation && <Alert message={message} type="error" />}
      <ImgCrop aspect={aspect || 6 / 4} grid>
        <Upload
          className={error ? 'error' : ''}
          beforeUpload={beforeUploads}
          accept="image/*"
          headers={{
            // "Access-Control-Allow-Origin": "https://nemiedu.com/api/media",
            Authorization: `Bearer ${accessToken}`
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "POST",
          }}
          action={`${apiURL}/media`}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={handlePreview}
          // showUploadList={false}
        >
          {fileList.length < 1 && fileInfo && fileInfo.name === '' && '+ Upload Thumbnail'}
          {/* {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )} */}
        </Upload>
      </ImgCrop>
      {/* <CustomizedDialogs> */}
      {fileList.length > 0 && (
        <CustomizedDialogs>
          <img
            alt="example"
            style={{ width: '100%' }}
            src={previewImage}
            visible={previewVisible}
            title={previewTitle}
            // footer={null}
            onCancel={() => handleCancel()}
          />
        </CustomizedDialogs>
      )}
    </div>
  );
}
