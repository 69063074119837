import {
  ASSOCIATE_CREATE_SUCCESS,
  ASSOCIATE_LIST_SUCCESS,
  ASSOCIATE_INFO_SUCCESS,
  ASSOCIATE_UPDATE_SUCCESS,
  ASSOCIATE_DELETE_SUCCESS,
  ASSOCIATE_FILTER_HANDLING,
  ASSOCIATE_SEARCH_NAME,
  ASSOCIATE_ACTION_SUCCESS
} from '../actionTypes/associate';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';
import { SIGNOUT_AUTH_USER_SUCCESS } from '../actionTypes/auth';
// import AllDataService from '../services/all.service';
import { authMemberSignOut } from './auth';
import axiosHttp from '../services/axiosCommon';

export const getAssociateList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/associate', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      if (data.status === 200) {
        dispatch({
          type: ASSOCIATE_LIST_SUCCESS,
          payload: { ...data, queryInfo }
        });
      }
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/associate/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: ASSOCIATE_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const getAssociateInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/associate', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ASSOCIATE_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createAssociate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.post('/associate', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/associate/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: ASSOCIATE_CREATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateAssociate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put('/associate', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/associate/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: ASSOCIATE_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const actionAssociate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  const { queryInfo } = infoData;
  try {
    const result = await axiosHttp.put('/associate/action', { ...infoData, queryInfo: undefined });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/associate/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch(getAssociateList({ ...queryInfo }));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteAssociate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/associate', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/associate/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: ASSOCIATE_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: ASSOCIATE_SEARCH_NAME,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: ASSOCIATE_FILTER_HANDLING,
    payload: info
  });
};
