import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import AuthType from '../components/authentication/AuthType';
import Logo from '../components/Logo';
import Snackbars from '../components/Snackbars';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 664,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}));
const StackStyle = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}));
// ----------------------------------------------------------------------

export default function Login() {
  const subDomain = window.location.host.split('.')[0];
  // console.log({ location }, window.location.host.split('.')[0]);
  return (
    <RootStyle title="Login | TransferItNow Admin">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_dashboard.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <RouterLinkStyle to="/">
            <Logo
              sx={{
                display: 'unset'
              }}
            />
          </RouterLinkStyle>
          <StackStyle sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to TransferItNow
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </StackStyle>
          {/* <AuthSocial /> */}
          {/* <AuthType subDomain={subDomain} /> */}

          <LoginForm subDomain={subDomain} />
          {/* 
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
