export const IMAGE_GALLERY_CREATE = 'IMAGE_GALLERY_CREATE';
export const IMAGE_GALLERY_CREATE_SUCCESS = 'IMAGE_GALLERY_CREATE_SUCCESS';
export const IMAGE_GALLERY_CREATE_ERROR = 'IMAGE_GALLERY_CREATE_ERROR';
export const IMAGE_GALLERY_LIST = 'IMAGE_GALLERY_LIST';
export const IMAGE_GALLERY_LIST_SUCCESS = 'IMAGE_GALLERY_LIST_SUCCESS';
export const IMAGE_GALLERY_LIST_ERROR = 'IMAGE_GALLERY_LIST_ERROR';
export const IMAGE_GALLERY_INFO = 'IMAGE_GALLERY_INFO';
export const IMAGE_GALLERY_INFO_SUCCESS = 'IMAGE_GALLERY_INFO_SUCCESS';
export const IMAGE_GALLERY_INFO_ERROR = 'IMAGE_GALLERY_INFO_ERROR';
export const IMAGE_GALLERY_UPDATE_SUCCESS = 'IMAGE_GALLERY_UPDATE_SUCCESS';
export const IMAGE_GALLERY_UPDATE_ERROR = 'IMAGE_GALLERY_UPDATE_ERROR';
export const IMAGE_GALLERY_DELETE_SUCCESS = 'IMAGE_GALLERY_DELETE_SUCCESS';
export const IMAGE_GALLERY_FILTER_HANDLING = 'IMAGE_GALLERY_FILTER_HANDLING';
export const IMAGE_GALLERY_SEARCH_NAME = 'IMAGE_GALLERY_SEARCH_NAME';
export const IMAGE_GALLERY_ACTION_SUCCESS = 'IMAGE_GALLERY_ACTION_SUCCESS';
export const IMAGE_GALLERY_CATEGARY = 'IMAGE_GALLERY_CATEGARY';
export const IMAGE_GALLERY_ALL_LIST = 'IMAGE_GALLERY_ALL_LIST';
