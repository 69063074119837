// import { SIGNIN_USER_ERROR, SIGNIN_USER_SUCCESS } from '../actionTypes/auth';
import { initURL } from '../actions/common';
import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  ON_SHOW_MESSAGE_IMAGE,
  ON_HIDE_MESSAGE_IMAGE,
  INIT_URL
} from '../actionTypes/common';

const INIT_STATE = {
  authMemberMessage: '',
  loader: false,
  showMessage: false,
  messageStatus: '',
  message: '',
  imageValidation: false,
  initURL: '',
  dialog: false
};

function AuthReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: payload
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        showMessage: false
      };
    }

    case ON_SHOW_MESSAGE: {
      console.log({ payload }, 'ON_SHOW_MESSAGE');
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: payload.error ? 'error' : 'success',
        message: payload.memberMessage
      };
    }
    case ON_HIDE_MESSAGE: {
      console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: false
      };
    }

    case ON_SHOW_MESSAGE_IMAGE: {
      console.log({ payload }, 'ON_SHOW_MESSAGE');
      return {
        ...state,
        loader: false,
        showMessage: true,
        imageValidation: true,
        messageStatus: payload.error ? 'error' : 'success',
        message: payload.authMemberMessage
      };
    }
    case ON_HIDE_MESSAGE_IMAGE: {
      console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: false,
        imageValidation: false
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
