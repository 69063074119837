import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../../components/Page';
import {
  AppAndroidClicked,
  AppIOSClicked,
  AppTotalContact,
  AppTotalUser,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppTotalVisitor
} from '../../components/_dashboardNew';
import { getDashboardAnalytics } from '../../appRedux/actions/dashboard';
import { CopyClipboard } from '../../components/copyToClipboard';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const { authMember } = useSelector((state) => ({
    authMember: state.auth.authMember
  }));
  useEffect(() => {
    dispatch(getDashboardAnalytics({}));
  }, []);
  useEffect(() => {
    if (authMember && authMember.name && authMember.name !== '') {
      console.log({ name: authMember.name });
      const name = authMember.name.split(' ');

      setName(name.length > 0 ? name[0] : '');
    } else {
      setName('');
    }
  }, [authMember]);
  // console.log({ authMemberName: authMember && authMember.name ? authMember.name.split(' ')[0] : '' });
  return (
    <Page title="Dashboard | TransferItNow Admin">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi {name}, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          {authMember && authMember.accessLevel === 15 && (
            <Grid item xs={12} sm={12} md={12}>
              <CopyClipboard />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <AppAndroidClicked />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppIOSClicked />
          </Grid>
          {authMember && authMember.accessLevel !== 15 ? (
            <Grid item xs={12} sm={6} md={3}>
              <AppTotalUser />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={3}>
              <AppTotalVisitor />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalContact />
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits />
          </Grid> */}
          {/* d/ */}
          {/* {authMember && authMember.accessLevel !== 15 ? (
            <Grid item xs={12} md={12} lg={12}>
              <AppTrafficBySite />
            </Grid>
          ) : (
            ''
          )} */}
        </Grid>
      </Container>
    </Page>
  );
}
