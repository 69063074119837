import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import powerOutline from '@iconify/icons-eva/power-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actionTemplate, deleteTemplate } from '../../../appRedux/actions/emailTemplate';

// ----------------------------------------------------------------------

export default function AdminMoreMenu({ templateId, active, queryInfo }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const onClickAction = (templateId, status, queryInfo) => {
    dispatch(actionTemplate({ templateId, active: !status, queryInfo }));
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => onClickAction(templateId, active, queryInfo)}
        >
          <ListItemIcon>
            <Icon
              icon={active ? trash2Outline : powerOutline}
              width={24}
              height={24}
              color={'error'}
              // sx={{ color: 'error' }}
            />
          </ListItemIcon>
          {/* Activated */}
          <ListItemText
            color={active ? 'error' : ''}
            sx={{ color: active ? 'error' : '' }}
            primary={active ? 'Discontinue' : 'Activated'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`/settings/email-template/${templateId}/update`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
