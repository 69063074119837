//   column_Handling;
export const COLUMN_HANDLING = [
  {
    id: 'Name',
    key: 'name',
    label: 'Name',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  { id: 'Email', key: 'email', label: 'Email', align: 'left', visibility: true, isFilter: true },
  {
    id: 'CompanyName',
    key: 'companyName',
    label: 'Company Name',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'ContactNumber',
    key: 'contactNumber',
    label: 'Contact Number',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  // usedStorage

  {
    id: 'storagePlanName',
    key: 'storagePlanInfo',
    childKey: 'name',
    label: 'Plan Name',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'usedStorage',
    key: 'usedStorage',
    label: 'Used Storage',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'storagePlanLimit',
    key: 'storagePlanInfo',
    childKey: 'accessData',
    label: 'Storage Limit',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'storagePlanValidity',
    key: 'storagePlanInfo',
    childKey: 'expiration',
    label: 'Validity',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Status',
    key: 'active',
    label: 'Status',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'CreatedAt',
    key: 'createdAt',
    label: 'Created At',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];

export const PLATFORM_TYPE = [
  {
    type: 'Contactr',
    title: 'YouTube',
    icon: 'youtube'
  },
  {
    type: 'Contactr',
    title: 'Facebook',
    icon: 'facebook'
  },
  {
    type: 'Contactr',
    title: 'Instagram',
    icon: 'instagram'
  },
  {
    type: 'Contactr',
    title: 'TikTok',
    icon: 'tiktok'
  },
  {
    type: 'Contactr',
    title: 'Vimeo',
    icon: 'vimeo'
  },
  // {
  //   type: 'Vlogging',
  //   title: 'YouTube',
  //   icon: 'youtube'
  // },
  // {
  //   type: 'Vlogging',
  //   title: 'Facebook',
  //   icon: 'facebook'
  // },
  // {
  //   type: 'Vlogging',
  //   title: 'Instagram',
  //   icon: 'instagram'
  // },
  // {
  //   type: 'Vlogging',
  //   title: 'TikTok',
  //   icon: 'tiktok'
  // },
  // {
  //   type: 'Vlogging',
  //   title: 'Vimeo',
  //   icon: 'vimeo'
  // },
  {
    type: 'Blogging',
    title: 'Website',
    icon: 'website'
  }
];
