import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import Snackbars from '../../components/Snackbars';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import { hideLoader, hideMessage } from '../../appRedux/actions/common';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { showMessage, messageStatus, routeLevel, loader, accessToken, authMember } = useSelector(
    (state) => ({
      showMessage: state.common.showMessage,
      messageStatus: state.common.messageStatus,
      message: state.common.message,
      loader: state.common.loader,
      accessToken: state.auth.accessToken,
      routeLevel: state.auth.routeLevel,
      authMember: state.auth.authMember
    })
  );
  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    if (loader) dispatch(hideLoader());
    if (!accessToken || accessToken === null) navigate('/login', { replace: true });
  }, [accessToken]);

  // if (authMember === null || !accessToken || accessToken === null) return;
  // console.log('dashboard layout-----props_App-------layout_one');
  // if (!accessToken || !authMember) return navigate('/login', { replace: true });
  return (
    <RootStyle>
      <CircularIndeterminate key={Math.random()} />
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      {/* <Snackbars key={Math.random()} /> */}
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
