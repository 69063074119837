import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import arrowBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import { codeSend } from '../../../appRedux/actions/auth';
import { showLoader } from '../../../appRedux/actions/common';
// ----------------------------------------------------------------------

const ButtonStyle = styled(Button)(({ theme }) => ({
  '&:hover': {
    // color: theme.palette.primary.main,
    backgroundColor: 'unset',
    textDecoration: 'underline'
  }
}));
const INITIAL_STATE = {
  email: ''
};
export default function ForgotForm({ subDomain }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log({ subDomain });
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const { loader, tempToken } = useSelector((state) => ({
    loader: state.common.loader,
    tempToken: state.auth.tempToken
  }));

  useEffect(() => {
    if (tempToken) {
      navigate(`/verify-code/${tempToken}`, { replace: true });
    }
  }, [tempToken]);

  const FormSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data }, 'update');
      dispatch(showLoader());
      dispatch(codeSend({ ...data, type: 'RequestResetPassword' }, subDomain));
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="authMembername"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormLabel
            alignItems="end"
            // control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember your password?"
          >
            Remember your password? &nbsp;&nbsp;
            <Link component={RouterLink} variant="subtitle2" to="/login">
              Login
            </Link>
          </FormLabel> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loader}>
          Reset Password
        </LoadingButton>

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ my: 1 }}
        ></Stack>

        <ButtonStyle
          component={RouterLink}
          variant="subtitle2"
          to="/login"
          startIcon={<Icon icon={arrowBackFill} />}
        >
          Return to sign in
        </ButtonStyle>
      </Form>
    </FormikProvider>
  );
}
