import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Icon } from '@iconify/react';
import IconButton from '@material-ui/core/IconButton';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import CloseIcon from '@iconify/icons-ant-design/close-outlined';
import { useDispatch, useSelector } from 'react-redux';

import { hideMessage } from '../appRedux/actions/common';

const SnackbarContentSuccess = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.success.main
}));
const SnackbarContentError = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.error.main
}));

export default function AlertMassage() {
  const dispatch = useDispatch();
  const { open, messageStatus, message, loader } = useSelector((state) => ({
    open: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
    loader: state.common.loader
  }));
  console.log({ open }, '-----AlertMassage--------');
  useEffect(() => {
    console.log({ open });
    if (open) {
      const timeout = setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [open]);
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideMessage());
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        // autoHideDuration={2000}
        onClose={() => handleClose()}
        variant="warning"
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
      >
        {messageStatus === 'success' ? (
          <SnackbarContentSuccess
            message={message}
            action={[
              <IconButton key="close" onClose={() => handleClose()}>
                <Icon icon={CloseIcon} style={{ color: '#ffffff' }} />
              </IconButton>
            ]}
          />
        ) : (
          <SnackbarContentError
            message={message}
            action={[
              <IconButton key="close" onClose={() => handleClose()}>
                <Icon icon={CloseIcon} style={{ color: '#ffffff' }} />
              </IconButton>
            ]}
          />
        )}

        {/* <Alert severity="error">This is an error message!</Alert>
        <Alert severity="warning">This is a warning message!</Alert>
        <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert> */}
      </Snackbar>
    </div>
  );
}
