import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import folderShared from '@iconify/icons-ic/folder-shared';
import powerOutline from '@iconify/icons-eva/power-outline';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AlertDialogSlide from '../AlertDialogSlide';

import { showLoader } from '../../appRedux/actions/common';
import { actionTransfer } from '../../appRedux/actions/transferHistory';

// ----------------------------------------------------------------------

export const FILTER_CATEGORY_OPTIONS = ['All', 'Activated', 'Processing', 'Expired', 'Banned'];
export default function AdminMoreMenu({ transferId, active, queryInfo, status }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertContant, setAlertContant] = useState({});

  useEffect(() => {
    setReason('');
  }, []);
  const onHandleAgree = () => {
    dispatch(showLoader());
    setAlertDialog(false);
    dispatch(actionTransfer({ ...alertContant, reason }));
  };
  const AlertProps = {
    open: alertDialog,
    title: 'Are you sure?',
    handleClose: () => {
      setAlertDialog(false);
    },
    handleAgree: onHandleAgree
  };
  const onChangeReason = (text) => {
    setReason(text);
  };
  // status= status==="Done"?"Activated":
  let newStatus = 'Activated';
  if (status === 'Banned') {
    newStatus = 'Banned';
  } else if (status === 'Process') {
    newStatus = 'Processing';
  } else if (status === 'Expire') {
    newStatus = 'Expired';
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {status !== 'Expire' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              setIsOpen(false);
              setAlertDialog(true);
              setAlertContant({
                transferId,
                status: newStatus === 'Banned' ? 'Activated' : 'Banned',
                queryInfo
              });
              // onClickAction(transferId, newStatus === 'Banned' ? newStatus : 'Banned', queryInfo)
            }}
          >
            <ListItemIcon>
              <Icon
                icon={active ? trash2Outline : powerOutline}
                width={24}
                height={24}
                color={'error'}
                // sx={{ color: 'error' }}
              />
            </ListItemIcon>
            {/* Activated */}
            <ListItemText
              color={active ? 'error' : ''}
              sx={{ color: active ? 'error' : '' }}
              primary={newStatus === 'Banned' ? 'Activated' : 'Banned'}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        <MenuItem
          component={RouterLink}
          to={`/transfer/${transferId}/details`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={folderShared} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Transfer Details" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <AlertDialogSlide>
        <div {...AlertProps}>
          {`Are you sure want to ${
            alertContant && alertContant.status ? alertContant.status.toLowerCase() : ''
          } this transfers activity?`}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            placeholder="Reason..."
            type="text"
            fullWidth
            variant="standard"
            value={reason}
            onChange={(e) => {
              const text = e.target.value;
              console.log({ text });
              onChangeReason(text);
            }}
            // required
          />
        </div>
      </AlertDialogSlide>
    </>
  );
}
