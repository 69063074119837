import { Icon } from '@iconify/react';
import { useRef, useState, useEffect, useCallback } from 'react';
import editFill from '@iconify/icons-ant-design/forward-fill';

import folderShared from '@iconify/icons-ic/folder-shared';
import powerOutline from '@iconify/icons-eva/power-outline';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actionUser } from '../../appRedux/actions/user';
import AlertDialogSlide from '../AlertDialogSlide';
import { showLoader } from '../../appRedux/actions/common';

// ----------------------------------------------------------------------

export default function AdminMoreMenu({ userId, active, queryInfo }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  // const onClickAction = (userId, status, queryInfo) => {
  //   // dispatch(actionUser({ userId, active: !status, queryInfo }));
  // };

  const [reason, setReason] = useState('');
  const [alertDialog, setAlertDialog] = useState(false);

  useEffect(() => {
    setReason('');
  }, []);
  const onHandleAgree = () => {
    dispatch(showLoader());
    setAlertDialog(false);
    dispatch(actionUser({ userId, active: !active, queryInfo, reason }));
  };
  const AlertProps = {
    open: alertDialog,
    title: 'Are you sure?',
    handleClose: () => {
      setAlertDialog(false);
    },
    handleAgree: onHandleAgree
  };
  const onChangeReason = (text) => {
    setReason(text);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        // keepMounted={false}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            setAlertDialog(true);
          }}
        >
          <ListItemIcon>
            <Icon
              icon={active ? trash2Outline : powerOutline}
              width={24}
              height={24}
              color={'error'}
              // sx={{ color: 'error' }}
            />
          </ListItemIcon>
          {/* Activated */}
          <ListItemText
            color={active ? 'error' : ''}
            sx={{ color: active ? 'error' : '' }}
            primary={active ? 'Discontinue' : 'Activated'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`/transfer/${userId}/activity`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={folderShared} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Transfer Activity" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <AlertDialogSlide>
        <div {...AlertProps}>
          {`Are you sure want to ${active ? 'discontinue' : 'activated'} this user?`}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            placeholder="Reason..."
            type="text"
            fullWidth
            variant="standard"
            value={reason}
            onChange={(e) => {
              const text = e.target.value;
              console.log({ text });
              onChangeReason(text);
            }}
            required
          />
        </div>
      </AlertDialogSlide>
    </>
  );
}
