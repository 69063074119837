import {
  EMAIL_SETTING_INFO_SUCCESS,
  EMAIL_SETTING_UPDATE_SUCCESS
} from '../actionTypes/emailSetting';

const INIT_STATE = {
  isSuccess: false,
  templateInfo: {},

  isUpdate: false
};

function TemplateReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_SETTING_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        templateInfo: payload.data,
        isSuccess: true,
        isUpdate: true
      };
    }

    case EMAIL_SETTING_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        templateInfo: payload.data
        // isSuccess: true
      };
    }

    default:
      return state;
  }
}

export default TemplateReducer;
