export const EMAIL_TEMPLATE_CREATE = 'EMAIL_TEMPLATE_CREATE';
export const EMAIL_TEMPLATE_CREATE_SUCCESS = 'EMAIL_TEMPLATE_CREATE_SUCCESS';
export const EMAIL_TEMPLATE_CREATE_ERROR = 'EMAIL_TEMPLATE_CREATE_ERROR';
export const EMAIL_TEMPLATE_LIST = 'EMAIL_TEMPLATE_LIST';
export const EMAIL_TEMPLATE_LIST_SUCCESS = 'EMAIL_TEMPLATE_LIST_SUCCESS';
export const EMAIL_TEMPLATE_LIST_ERROR = 'EMAIL_TEMPLATE_LIST_ERROR';
export const EMAIL_TEMPLATE_INFO = 'EMAIL_TEMPLATE_INFO';
export const EMAIL_TEMPLATE_INFO_SUCCESS = 'EMAIL_TEMPLATE_INFO_SUCCESS';
export const EMAIL_TEMPLATE_INFO_ERROR = 'EMAIL_TEMPLATE_INFO_ERROR';
export const EMAIL_TEMPLATE_UPDATE_SUCCESS = 'EMAIL_TEMPLATE_UPDATE_SUCCESS';
export const EMAIL_TEMPLATE_UPDATE_ERROR = 'EMAIL_TEMPLATE_UPDATE_ERROR';
export const EMAIL_TEMPLATE_DELETE_SUCCESS = 'EMAIL_TEMPLATE_DELETE_SUCCESS';
export const EMAIL_TEMPLATE_FILTER_HANDLING = 'EMAIL_TEMPLATE_FILTER_HANDLING';
export const EMAIL_TEMPLATE_SEARCH_NAME = 'EMAIL_TEMPLATE_SEARCH_NAME';
export const EMAIL_TEMPLATE_ACTION_SUCCESS = 'EMAIL_TEMPLATE_ACTION_SUCCESS';
export const EMAIL_TEMPLATE_CATEGARY = 'EMAIL_TEMPLATE_CATEGARY';
