import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';

import { Icon } from '@iconify/react';
import arrowBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// components
import Logo from '../components/Logo';
import Page from '../components/Page';
import { VerifyCodeForm } from '../components/authentication/verifyCode/index';

import { getTempToken } from '../appRedux/actions/auth';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(12, 0)
}));

const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}));
const StackStyle = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}));
// ----------------------------------------------------------------------

export default function VerifyCode() {
  const subDomain = window.location.host.split('.')[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { tempToken, type } = params;
  const { verifyType, code, loader, email } = useSelector((state) => ({
    verifyType: state.auth.verifyType,
    loader: state.common.loader,
    email: state.auth.email,
    code: state.auth.code
  }));
  useEffect(() => {
    if (!tempToken) {
      navigate('/login', { replace: true });
    } else {
      dispatch(getTempToken(tempToken, subDomain));
    }
    if (code)
      navigate(`/request-new-password-set/ ${code}/tempToken`, {
        replace: true
      });
  }, [tempToken, code]);

  return (
    <RootStyle title="Verify Code | TransferItNow Admin">
      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <RouterLinkStyle to="/">
            <Logo
              sx={{
                display: 'unset'
              }}
            />
          </RouterLinkStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h3" gutterBottom>
              Please check your email!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              We have emailed a 6-digit confirmation code to {email}, please enter the code in below
              box to verify your email.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <VerifyCodeForm subDomain={subDomain} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
