import React, { useState } from 'react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Card, Stack, Container, Typography, Grid } from '@material-ui/core';
// components
import Page from '../../components/Page';

import { FormView } from '../../components/_profile';
// import { Image } from '../../components/_admin';
// ----------------------------------------------------------------------

const CardStyleImage = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5, 3),
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '100%'
}));
const CardStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5, 3),
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
}));
const SpanStyle = styled('span')(({ theme }) => ({
  margin: '16px auto 0px',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.5,
  color: 'rgb(99, 115, 129)',
  display: 'block',
  textAlign: 'center',
  position: 'absolute',
  top: 200,
  width: '86%'
}));
// ----------------------------------------------------------------------

export default function Admin() {
  return (
    <Page title="Admin | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>
        <CardStyle>
          <FormView />
        </CardStyle>
      </Container>
    </Page>
  );
}
