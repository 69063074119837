import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TablePagination,
  Grid
} from '@material-ui/core';
// components
import Page from '../../components/Page';
import { ListToolbar, ListView } from '../../components/_contactDetails';
//
import { getContactList } from '../../appRedux/actions/contactDetails';
import { initURL } from '../../appRedux/actions/common';
import { CopyClipboard } from '../../components/copyToClipboard';

// ----------------------------------------------------------------------

export default function ContactList() {
  const dispatch = useDispatch();
  const { contactId } = useParams();

  const { contactTypes, isUpdate, queryInfo, page, limit, totalRecords, authMember } = useSelector(
    (state) => ({
      contactTypes: state.contactDetails.contactType,
      isUpdate: state.contactDetails.isUpdate,
      queryInfo: state.contactDetails.queryInfo,
      page: state.contactDetails.queryInfo.page,
      limit: state.contactDetails.queryInfo.limit,
      totalRecords: state.contactDetails.totalRecords,
      loader: state.common.loader,
      authMember: state.auth.authMember
    })
  );

  useEffect(() => {
    if (isUpdate) {
      dispatch(initURL('#'));
      dispatch(getContactList({ ...queryInfo, contactId }));
    } else {
      dispatch(initURL('#'));
      dispatch(getContactList({ ...queryInfo, contactId, page: 0, limit: 10 }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getContactList({ ...queryInfo, contactId, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getContactList({ ...queryInfo, contactId, page: 0, limit: event.target.value }));
  };

  return (
    <Page title="Contact | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${contactTypes} Contact Details List`}
          </Typography>
          {/* {authMember && authMember.accessLevel !== 15 ? (
            <Button
              variant="contained"
              component={RouterLink}
              to="/contact/new"
              startIcon={<Icon icon={plusFill} />}
            >
              Create Contact
            </Button>
          ) : (
            ''
          )} */}
        </Stack>

        <Card>
          <ListToolbar />

          <ListView />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {/* <CardView /> */}
      </Container>
    </Page>
  );
}
