import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { CountryList } from '../../utils/constant';
import { createAssociate, updateAssociate } from '../../appRedux/actions/associate';
import { showLoader } from '../../appRedux/actions/common';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 0, 0)
}));
const initialValue = {
  name: '',
  idNumber: '',
  email: '',
  contactNumber: '',
  country: 'India',
  city: '',
  postalCode: '',
  address: ''
};

FormView.propTypes = {
  isUpdate: PropTypes.bool
};

export default function FormView({ isUpdate }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, associateInfo, url, loader } = useSelector((state) => ({
    isSuccess: state.associate.isSuccess,
    associateInfo: state.associate.associateInfo,
    messageStatus: state.common.messageStatus,
    url: state.common.initURL,
    loader: state.common.loader
  }));

  useEffect(() => {
    console.log({ isSuccess, url });
    if (url === '/associate/list' && isSuccess) {
      console.log({ isSuccess, url });
      const timeout = setTimeout(() => {
        console.log({ isSuccess, url });
        navigate(url, { replace: true });
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isUpdate) setActive(associateInfo.active);
  }, [url, isSuccess, associateInfo]);

  console.log({ isUpdate, associateInfo: associateInfo.active });
  const [active, setActive] = useState(isUpdate ? associateInfo.active : true);
  const Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Associate Name is required.'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required.'),
    idNumber: Yup.string().min(8, 'Too Short!').required('ID / Registration Number is required.'),
    contactNumber: Yup.string().min(8, 'Too Short!').required('Contact Number is required.'),
    address: Yup.string().min(4, 'Too Short!').required('Address is required.'),
    postalCode: Yup.string().min(5, 'Too Short!').required('Pin Code is required.'),
    city: Yup.string().required('City is required.'),
    country: Yup.string().required('Country is required.')
  });
  console.log({ associateInfo });
  const initValues = associateInfo && associateInfo._id && isUpdate ? associateInfo : initialValue;

  const formik = useFormik({
    enableReinitialize: isUpdate || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      console.log({ data, isUpdate }, '------------');
      dispach(showLoader());
      if (isUpdate)
        dispach(
          updateAssociate({ ...data, _id: undefined, associateId: associateInfo._id, active })
        );
      else dispach(createAssociate(data));
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  console.log({ errors, touched, handleSubmit, getFieldProps, values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              fullWidth
              label="Associate Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              type="idNumber"
              label="ID / Registration Number"
              {...getFieldProps('idNumber')}
              error={Boolean(touched.idNumber && errors.idNumber)}
              helperText={touched.idNumber && errors.idNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              type="email"
              label="Email Address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              type="number"
              label="Contact Number"
              {...getFieldProps('contactNumber')}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Country"
                {...getFieldProps('country')}
                error={Boolean(touched.country && errors.country)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {CountryList.map((list) => (
                  <MenuItem value={list.name}>{list.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{touched.country && errors.country}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              type="number"
              label="Pin Code"
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helperText={touched.postalCode && errors.postalCode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {isUpdate && (
              <FormControlLabel
                value="active"
                control={
                  <Switch
                    color="primary"
                    checked={active}
                    onChange={(event) => setActive(event.target.checked)}
                  />
                }
                label="Active"
                labelPlacement="active"
              />
            )}
          </Grid>
        </Grid>

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {isUpdate ? 'Update Associate' : ' Create Associate'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
