import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { CountryList } from '../../utils/constant';
import { createContact, updateContact } from '../../appRedux/actions/contact';
import { showLoader } from '../../appRedux/actions/common';
import ImageUpload from '../ImageUpload';
import ImageUploadSingle from '../ImageUploadSingle';
import TextEditer from '../TextEditer';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 0, 0)
}));
const initialValue = {
  name: '',
  contactCode: '',
  sortDescription: '',
  description: '',
  googleStoreUrl: '',
  appleStoreUrl: '',

  thumbnail: {
    name: '',
    size: '',
    status: '',
    type: '',
    uid: '',
    url: ''
  }
};

const StyleText = styled('p')(({ theme }) => ({
  // margin: '0px auto 16px',
  fontWeight: 400,
  fontSize: '0.9rem',
  lineHeight: 1.5,
  color: theme.palette.grey[500],
  textTransform: 'uppercase'
}));

FormView.propTypes = {
  isUpdate: PropTypes.bool
};

export default function FormView({ isUpdate }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const [removeFileList, setRemoveFileList] = useState([]);

  const { isSuccess, contactInfo, url, loader } = useSelector((state) => ({
    isSuccess: state.contact.isSuccess,
    contactInfo: state.contact.contactInfo,
    messageStatus: state.common.messageStatus,
    url: state.common.initURL,
    loader: state.common.loader
  }));

  const [fileList, setFileList] = useState([]);
  const [fileInfo, setFileInfo] = useState({});
  const [description, setDescription] = useState(contactInfo && contactInfo.description);

  useEffect(() => {
    console.log({ isSuccess, url });
    if (url === '/contact/list' && isSuccess) {
      console.log({ isSuccess, url });
      const timeout = setTimeout(() => {
        console.log({ isSuccess, url });
        navigate(url, { replace: true });
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isUpdate) setActive(contactInfo.active);
  }, [url, isSuccess, contactInfo]);
  const [active, setActive] = useState(isUpdate === true ? contactInfo.active : true);

  const Schema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is required.'),
    contactCode: Yup.string().required('Contact Code is required.'),
    sortDescription: Yup.string()
      .min(90, 'Short Description must be at least 90 characters.')
      .max(120, 'Short Description must be at most 120 characters.')
      .required('Short Description is required.'),
    description: Yup.string(),
    googleStoreUrl: Yup.string()
      .url('URL is not valid.')
      .required('Google Play Store URL is required.'),
    appleStoreUrl: Yup.string().url('URL is not valid.'),

    thumbnail: Yup.object().shape({
      name: Yup.string().required('Thumbnail is required.'),
      url: Yup.string().required('Thumbnail is required.')
    })
  });
  const initValues = contactInfo && contactInfo._id && isUpdate ? contactInfo : initialValue;

  const formik = useFormik({
    enableReinitialize: isUpdate || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      dispach(showLoader());
      if (isUpdate)
        dispach(
          updateContact({
            ...data,
            _id: undefined,
            contactId: contactInfo._id,
            active
          })
        );
      else dispach(createContact({ ...data }));
    }
  });
  const onSetFileInfo = (file) => {
    setFieldTouched('thumbnail', true);
    console.log({ file }, '------fileList----');
    if (file.status && file.status !== 'uploading') {
      if (file.status !== 'removed' && file.response) {
        file.originFileObj = undefined;
        file.lastModified = undefined;
        file.lastModifiedDate = undefined;
        file.percent = undefined;
        file.thumbUrl = undefined;
        file.url = file.response.data;
        delete file.originFileObj;
        delete file.lastModified;
        delete file.lastModifiedDate;
        delete file.percent;
        delete file.thumbUrl;
        delete file.response;
        delete file.xhr;
        console.log({ file });
        setFileInfo(file);
        // dispatch(hideMessageImage());
      } else {
        setFileList(fileList.filter((row) => row.uid !== file.uid));
        console.log({ removeFileList, file });
        let removeList = removeFileList;
        if (removeList.length) {
          removeList.push(file);
        } else {
          removeList = [file];
        }
        console.log({ removeFileList, file, removeList });
        setRemoveFileList(removeList);
        setFileInfo({
          name: '',
          size: '',
          status: '',
          type: '',
          uid: '',
          url: ''
        });
      }
    }
  };
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    setFieldError
  } = formik;
  useEffect(() => {
    setFieldValue('thumbnail', fileInfo);
  }, [fileList, fileInfo]);
  useEffect(() => {
    setFieldValue('description', description);
  }, [description]);

  useEffect(() => {
    // dispatch(hideMessage());
    // dispatch(hideMessageImage());
    if (isUpdate) {
      setFileList(
        contactInfo &&
          contactInfo.thumbnail &&
          contactInfo.thumbnail.url &&
          contactInfo.thumbnail.url !== ''
          ? [contactInfo.thumbnail]
          : null
      );
      setFileInfo(
        contactInfo &&
          contactInfo.thumbnail &&
          contactInfo.thumbnail.url &&
          contactInfo.thumbnail.url !== ''
          ? contactInfo.thumbnail
          : {}
      );
    } else {
      setFileList([]);
      setFileInfo({});
    }

    // setFieldValue('thumbnail', contactInfo.thumbnail);
  }, [contactInfo, isUpdate]);
  console.log({
    errors,
    touched,
    values
  });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {' '}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4} id="CoverImage">
            <ImageUploadSingle
              aspect={12 / 8}
              fileList={fileList}
              setFileList={setFileList}
              fileInfo={fileInfo}
              setFileInfo={onSetFileInfo}
              error={touched.thumbnail && errors.thumbnail && errors.thumbnail.url}
            />
            <FormHelperText
              error={touched.thumbnail && errors.thumbnail && Boolean(errors.thumbnail.url)}
            >
              {touched.thumbnail && errors.thumbnail && errors.thumbnail.url}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  fullWidth
                  label="Contact Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  label="Contact Code"
                  {...getFieldProps('contactCode')}
                  error={Boolean(touched.contactCode && errors.contactCode)}
                  helperText={touched.contactCode && errors.contactCode}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Short Description"
                  {...getFieldProps('sortDescription')}
                  error={Boolean(touched.sortDescription && errors.sortDescription)}
                  helperText={touched.sortDescription && errors.sortDescription}
                  required
                  multiline
                  rows={6}
                  maxRows={6}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Google Play Store URL"
              {...getFieldProps('googleStoreUrl')}
              error={Boolean(touched.googleStoreUrl && errors.googleStoreUrl)}
              helperText={touched.googleStoreUrl && errors.googleStoreUrl}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Apple App Store URL"
              {...getFieldProps('appleStoreUrl')}
              error={Boolean(touched.appleStoreUrl && errors.appleStoreUrl)}
              helperText={touched.appleStoreUrl && errors.appleStoreUrl}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextEditer
              overView={contactInfo && contactInfo.description}
              buttonText={'Description'}
              onChangeContent={(description) => setDescription(description)}
            />
          </Grid>
        </Grid>
        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {isUpdate ? 'Update Contact' : ' Create Contact'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
