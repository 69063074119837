import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },
    '.css-1wlvelt-MuiButtonBase-root-MuiMenuItem-root .MuiListItemIcon-root, .css-3nx0c9-MuiButtonBase-root-MuiMenuItem-root .MuiListItemIcon-root':
      {
        minWidth: 21
      },
    '#imageCircle .ant-upload.ant-upload-select-picture-card': {
      width: 144,
      height: 144,
      padding: 8,
      margin: 'auto',
      textAlign: 'center',
      verticalAlign: 'top',
      backgroundColor: '#fafafa',
      border: '1px dashed #d9d9d9',
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'border-color 0.3s'
    },
    '#imageCircle .ant-upload-picture-card-wrapper': {
      display: 'inline-block',
      width: ' 100%'
    },
    '#imageCircle .ant-upload.ant-upload-select': {
      display: 'inline-block'
    },
    '#imageCircle .ant-upload': {
      outline: 'none',
      zIndex: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ' #imageCircle .upload-icon ': {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      opacity: '0',
      color: 'rgb(255, 255, 255)'
    },
    '#imageCircle .upload-icon p, #imageCircle .upload-icon-before p': {
      fontSize: 10
    },
    '#imageCircle span.ant-upload': {
      'background-color': '#f2f1f1',
      borderRadius: '50%',
      color: theme.palette.text.disabled
    },
    '#imageCircle span.ant-upload:hover': {
      borderRadius: '50%',
      'background-color': '#00000036',
      color: theme.palette.text.primary
    },
    '#imageCircle .ant-upload.ant-upload-select-picture-card:hover .upload-icon': {
      opacity: '.70',
      'background-color': '#141c22' // '#00000056'
    },
    '#placementDate div, #occupiedDate div': {
      // height: '100%',
      width: '100%'
      // borderRadius: '50%'
    },
    '.ant-upload.ant-upload-select-picture-card:hover ': {
      borderColor: theme.palette.primary.main
    }
  }
}))(() => null);

export default GlobalStyles;
