import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField } from '@material-ui/core';

import {
  getUserCode,
  getUserList,
  onChangeSearchCode,
  onChangeSearchName
} from '../../appRedux/actions/user';

// ----------------------------------------------------------------------

export default function UserCode({ touched, errors, viewOnly }) {
  const dispatch = useDispatch();
  const { searchText, userCode, userStatus, userMessage } = useSelector((state) => ({
    searchText: state.user.userCode.searchText,
    userCode: state.user.userCode.code,
    userStatus: state.user.userCode.status,
    userMessage: state.user.userCode.message
  }));
  useEffect(() => {
    if (userStatus) {
      const timeout = setTimeout(() => {
        dispatch(
          getUserCode({
            userCode: userCode !== '' ? userCode : undefined
          })
        );
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [userCode, userStatus]);

  const [openFilter, setOpenFilter] = useState(false);
  return (
    <TextField
      fullWidth
      label="User Code"
      value={userCode}
      onChange={(event) => dispatch(onChangeSearchCode(event.target.value))}
      error={Boolean(touched.userCode && errors.userCode)}
      helperText={touched.userCode && errors.userCode}
      disabled={viewOnly}
    />
  );
}
