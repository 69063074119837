import { DASHBOARD_INFO } from '../actionTypes/dashboard';

const INIT_STATE = {
  androidAuthMember: 0,
  iosAuthMember: 0,
  visitor: 0,
  user: 0,
  contacts: 0,
  trafficByUser: [],
  visitorView: [],
  visitorAndroidContact: [],
  visitoriOSContact: [],
  visitorContact: [],
  // visitorAndroidContact: [],
  visitorContactCount: [],
  visitorViewCount: [],
  visitorAndroidContactCount: [],
  visitoriOSContactCount: [],
  dummyDate: []
};

function DashboardReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    // const { trafficByUser, visitorView } = data;
    case DASHBOARD_INFO: {
      console.log({ payload });
      const visitorViews = payload.data.visitorView;
      const visitorContacts = payload.data.visitorContact;
      const visitoriOSContacts = payload.data.visitoriOSContact;
      const visitorAndroidContacts = payload.data.visitorAndroidContact;
      const visitorViewCounts = visitorViews.map((info) => {
        return info.totalCount;
      });
      // const visitorContactCounts = visitorContacts.map((info) => {
      //   return info.totalCount;
      // });
      // const visitoriOSContactCounts = visitoriOSContacts.map((info) => {
      //   return info.totalCount;
      // });
      // const visitorAndroidContactCounts = visitorAndroidContacts.map((info2) => {
      //   return info2.totalCount;
      // });
      return {
        ...state,
        androidAuthMember: payload.data.androidAuthMember,
        iosAuthMember: payload.data.iosAuthMember,
        visitor: payload.data.visitor,
        user: payload.data.user,
        contacts: payload.data.contacts,
        trafficByUser: payload.data.trafficByUser,
        visitorView: visitorViews,
        visitoriOSContact: visitoriOSContacts,
        visitorAndroidContact: visitorAndroidContacts,
        visitorViewCount: visitorViewCounts,
        // visitorContactCount: visitorContactCounts,
        // visitoriOSContactCount: visitoriOSContactCounts,
        // visitorAndroidContactCount: visitorAndroidContactCounts,
        dummyDate: payload.data.dummyDate
      };
    }

    default:
      return state;
  }
}

export default DashboardReducer;
