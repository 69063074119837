import React from 'react';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personFill from '@iconify/icons-eva/person-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import contact2Fill from '@iconify/icons-ant-design/contacts-filled';

import contact2Fill2 from '@iconify/icons-mingcute/contacts-fill';
import settings5Fill from '@iconify/icons-mingcute/settings-5-fill';
import mailFill from '@iconify/icons-mingcute/mail-open-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    accessLevel: [1, 2, 3, 4, 5, 15]
  },
  {
    title: 'associates',
    path: '/associate',
    icon: getIcon(personFill),
    accessLevel: [1],
    children: [
      { title: 'List', path: '/associate/list' },
      { title: 'Create', path: '/associate/new' }
    ]
  },
  {
    title: 'Members',
    path: '/member',
    icon: getIcon(peopleFill),
    accessLevel: [1, 2, 3],
    children: [
      { title: 'List', path: '/member/list' },
      { title: 'Create', path: '/member/new' }
    ]
  },
  {
    title: 'users',
    path: '/user/list',
    icon: getIcon(personAddFill),
    accessLevel: [1, 2, 3, 4]
    // children: [
    //   { title: 'List ', path: '/user/list' },
    //   { title: 'Create', path: '/user/new' }
    // ]
  },
  {
    title: 'contact us',
    path: '/contact/list',
    icon: getIcon(contact2Fill),
    accessLevel: [1, 2, 3, 4]
    // children: [
    //   { title: 'List ', path: '/contact/list' },
    //   { title: 'Create', path: '/contact/new' }
    // ]
  },
  {
    title: 'contact us',
    path: '/contact/list',
    icon: getIcon(contact2Fill),
    accessLevel: [15]
  },
  {
    title: 'settings',
    path: '/settings',
    icon: getIcon(settings5Fill),
    accessLevel: [1, 2, 3, 4]
    // multiple: true,
    // children: [
    //   {
    //     title: 'email-template',
    //     path: '/settings/email-template',
    //     icon: getIcon(mailFill),
    //     children: [
    //       { title: 'List ', path: '/settings/email-template/list' },
    //       { title: 'Create', path: '/settings/email-template/new' }
    //     ]
    //   }
    // ]
  }
];

export default sidebarConfig;
