export const TRANSFER_HISTORY_CREATE = 'TRANSFER_HISTORY_CREATE';
export const TRANSFER_HISTORY_CREATE_SUCCESS = 'TRANSFER_HISTORY_CREATE_SUCCESS';
export const TRANSFER_HISTORY_CREATE_ERROR = 'TRANSFER_HISTORY_CREATE_ERROR';
export const TRANSFER_HISTORY_LIST = 'TRANSFER_HISTORY_LIST';
export const TRANSFER_HISTORY_LIST_SUCCESS = 'TRANSFER_HISTORY_LIST_SUCCESS';
export const TRANSFER_HISTORY_LIST_ERROR = 'TRANSFER_HISTORY_LIST_ERROR';
export const TRANSFER_HISTORY_INFO = 'TRANSFER_HISTORY_INFO';
export const TRANSFER_HISTORY_INFO_SUCCESS = 'TRANSFER_HISTORY_INFO_SUCCESS';
export const TRANSFER_HISTORY_INFO_ERROR = 'TRANSFER_HISTORY_INFO_ERROR';
export const TRANSFER_HISTORY_UPDATE_SUCCESS = 'TRANSFER_HISTORY_UPDATE_SUCCESS';
export const TRANSFER_HISTORY_UPDATE_ERROR = 'TRANSFER_HISTORY_UPDATE_ERROR';
export const TRANSFER_HISTORY_DELETE_SUCCESS = 'TRANSFER_HISTORY_DELETE_SUCCESS';
export const TRANSFER_HISTORY_FILTER_HANDLING = 'TRANSFER_HISTORY_FILTER_HANDLING';
export const TRANSFER_HISTORY_SEARCH_NAME = 'TRANSFER_HISTORY_SEARCH_NAME';
