import {
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_INFO_SUCCESS,
  EMAIL_TEMPLATE_FILTER_HANDLING,
  EMAIL_TEMPLATE_SEARCH_NAME,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_CATEGARY
} from '../actionTypes/emailTemplate';

const INIT_STATE = {
  templateList: [],
  isSuccess: false,
  templateInfo: {},
  queryInfo: {
    page: 0,
    limit: 10
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [
    {
      id: 'Name',
      key: 'name',
      label: 'Name',
      align: 'left',
      pading: '0 3',
      visibility: true,
      isFilter: true
    },
    {
      id: 'SenderName',
      key: 'senderName',
      label: 'Sender Name',
      align: 'left',
      visibility: true,
      isFilter: true
    },
    {
      id: 'SenderEmail',
      key: 'senderEmail',
      label: 'Sender Email',
      align: 'left',
      visibility: true,
      isFilter: true
    },
    {
      id: 'TemplateType',
      key: 'templateType',
      label: 'Template Type',
      align: 'left',
      visibility: true,
      isFilter: true
    },
    {
      id: 'TemplateSubType',
      key: 'templateSubType',
      label: 'Template Sub-Type',
      align: 'left',
      visibility: true,
      isFilter: true
    },
    {
      id: 'Status',
      key: 'active',
      label: 'Status',
      align: 'left',
      visibility: false,
      isFilter: true
    },
    {
      id: 'CreatedAt',
      key: 'createdAt',
      label: 'Created At',
      align: 'left',
      visibility: false,
      isFilter: true
    },
    {
      id: 'Action',
      key: 'action',
      label: 'Action',
      align: 'left',
      visibility: true,
      isFilter: false
    }
  ],
  searchName: '',
  searchStatus: false,
  templateType: 'Activated',
  isUpdate: false,
  templateCategary: []
};

function TemplateReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_TEMPLATE_CATEGARY: {
      return {
        ...state,
        templateCategary: payload.data
      };
    }
    case EMAIL_TEMPLATE_LIST_SUCCESS: {
      console.log({ payload }, 'EMAIL_TEMPLATE_LIST_SUCCESS');
      // const list = payload.data && payload.data.length ? payload.data : [];
      const list = payload.data;
      console.log({ list, payload });
      return {
        ...state,
        searchStatus: false,
        templateList: list,
        queryInfo: {
          ...payload.queryInfo
        },
        totalRecords: payload.totalRecords,
        isSuccess: false
        // page: payload.queryInfo.page || 0
      };
    }

    case EMAIL_TEMPLATE_CREATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        templateList: [payload.data, ...state.templateList],
        isSuccess: true
      };
    }

    case EMAIL_TEMPLATE_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        templateList: state.templateList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        templateInfo: payload.data,
        isSuccess: true,
        isUpdate: true
      };
    }

    case EMAIL_TEMPLATE_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        templateInfo: payload.data
        // isSuccess: true
      };
    }
    case EMAIL_TEMPLATE_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        templateType: payload.templateType
      };
    }
    case EMAIL_TEMPLATE_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit }
      };
    }

    default:
      return state;
  }
}

export default TemplateReducer;
