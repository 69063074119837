import React, { useEffect, useState } from 'react';
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';

const CustomCheckbox = ({ platformType, item, formik, viewOnly }) => {
  const { values, touched, errors } = formik;
  //   const uniq = new Set(platformType.map((e) => JSON.stringify(e)));

  //   const res = Array.from(uniq).map((e) => JSON.parse(e));
  const res = platformType.filter(
    (elem, index, self) =>
      self.findIndex((t) => {
        return t.type === elem.type;
      }) === index
  );
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    console.log(
      {
        item,
        values: values.campaignName,
        status:
          values.campaignName &&
          values.campaignName[item.type] &&
          values.campaignName[item.type].status
      },
      'CustomCheckbox--11-'
    );
    if (
      values.campaignName &&
      values.campaignName[item.type] &&
      values.campaignName[item.type].status
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [values.campaignName]);
  return (
    <FormControlLabel
      value={item.type}
      control={<Checkbox value={checked} checked={checked} />}
      label={item.type}
      labelPlacement="end"
      key={item.type}
      //   name="campaignName"
      {...formik.getFieldProps(`campaignName[${item.type}].status`)}
      error={touched.campaignName && errors.campaignName && errors.campaignName.status}
      disabled={viewOnly}
    />
  );
};

export default CustomCheckbox;
