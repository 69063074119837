export const CONTACT_CREATE = 'CONTACT_CREATE';
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS';
export const CONTACT_CREATE_ERROR = 'CONTACT_CREATE_ERROR';
export const CONTACT_LIST = 'CONTACT_LIST';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_ERROR = 'CONTACT_LIST_ERROR';
export const CONTACT_INFO = 'CONTACT_INFO';
export const CONTACT_INFO_SUCCESS = 'CONTACT_INFO_SUCCESS';
export const CONTACT_INFO_ERROR = 'CONTACT_INFO_ERROR';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_ERROR = 'CONTACT_UPDATE_ERROR';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_FILTER_HANDLING = 'CONTACT_FILTER_HANDLING';
export const CONTACT_SEARCH_NAME = 'CONTACT_SEARCH_NAME';
