import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  IconButton,
  Box,
  TableHead
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowDownFill from '@iconify/icons-eva/arrow-down-fill';
import arrowUpFill from '@iconify/icons-eva/arrow-up-fill';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import ResultNotFound from '../ResultNotFound';
import { ListHead, MoreMenu } from '.';
//
import { fDate, fExpiredAt, mDate, mTime } from '../../utils/formatTime';
import { bytesToSize } from '../../utils/fileConstant';

// ----------------------------------------------------------------------

function Row(props) {
  const { row, columnHandling, queryInfo } = props;
  const { _id, name } = row;
  const [open, setOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [transferReceived, setTransferReceived] = useState([]);

  return (
    <>
      <TableRow key={`${_id} _TableRow_0`}>
        {columnHandling.map((col, index) => {
          const { id, key, childKey, align } = col;

          if (key === 'index') {
            return (
              <TableCell key={key} id="buttonTable" sx={{ padding: '6px 16px' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                    setContactInfo(row);
                    // setReceived(receivedTransfer);
                  }}
                  // className="text-black  "
                >
                  {open ? (
                    <Icon icon={arrowUpFill} />
                  ) : (
                    <Icon icon={arrowDownFill} />
                    // <InlineIcon name={'expand_more'} size="2xl" color={'black'} />
                  )}
                </IconButton>
              </TableCell>
            );
          }
          if (key === 'name') {
            return (
              <TableCell key={index} component="th" scope="row" padding="0 2" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar alt={name} src={name} />
                  <Typography variant="subtitle2" noWrap>
                    {/* <Link to={`/contact/${_id}/list`}> */}
                    {name}
                    {/* </Link> */}
                  </Typography>
                </Stack>
              </TableCell>
            );
          }
          // storagePlanInfo
          if (key === 'active') {
            return (
              <TableCell key={index} align="left">
                <Label variant="ghost" color={(row[key] === false && 'error') || 'success'}>
                  {row[key] === false ? 'Banned' : 'Active'}
                </Label>
              </TableCell>
            );
          }
          if (key === 'createdAt') {
            // return <TableCell align={align}>{row[key]}</TableCell>;
            return (
              <TableCell key={index} align={align}>
                {fDate(row[key])}
              </TableCell>
            );
          }
          return key === 'action' ? (
            <TableCell key={index} align={align}>
              <MoreMenu contactId={_id} index={index} />
            </TableCell>
          ) : (
            <TableCell align={align}>{row[key]}</TableCell>
          );
        })}
      </TableRow>
      <TableRow key={`${_id} _TableRow_1`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit key={`${_id} _Collapse`}>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Query
              </Typography>
              <Box
                sx={{
                  display: 'inline-flex',
                  m: 1,
                  p: 1,
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                  border: '1px solid',
                  borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                  borderRadius: 2,
                  fontSize: '0.875rem',
                  fontWeight: '700'
                }}
              >
                {`Subject: ${contactInfo.subject ? contactInfo.subject : 'N/A'}`}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  m: 1,
                  p: 1,
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                  border: '1px solid',
                  borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                  borderRadius: 2,
                  fontSize: '0.875rem',
                  fontWeight: '700'
                }}
              >
                {`Message: ${contactInfo.message ? contactInfo.message : 'N/A'}`}
              </Box>

              {/* <InnerList contactInfo={contactInfo} /> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default function ListView() {
  const dispatch = useDispatch();

  const {
    contactType,
    searchStatus,
    searchName,
    contactList,
    queryInfo,
    page,
    limit,
    columnHandling,
    loader
  } = useSelector((state) => ({
    contactType: state.contactDetails.contactType,
    searchStatus: state.contactDetails.searchStatus,
    searchName: state.contactDetails.searchName,
    contactList: state.contactDetails.contactList,
    queryInfo: state.contactDetails.queryInfo,
    page: state.contactDetails.queryInfo.page,
    limit: state.contactDetails.queryInfo.limit,
    columnHandling: state.contactDetails.columnHandling.filter(
      (item) => item.visibility || !item.isFilter
    ),
    loader: state.common.loader
  }));

  const emptyRows = page > 0 ? Math.max(0, limit - contactList.length) : 0;
  console.log({ emptyRows, emptyRows2: limit, length: contactList.length });
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ListHead headLabel={columnHandling} checkbox={false} isSortLabel={false} />
          <TableBody>
            {contactList &&
              contactList.length > 0 &&
              contactList.map((row) => {
                const { _id, name } = row;
                return (
                  <Row
                    key={`${row._id} _TableRow_9`}
                    row={row}
                    columnHandling={columnHandling}
                    queryInfo={queryInfo}
                  />
                  // <TableRow hover key={_id}>
                  //   {columnHandling.map((col, index) => {
                  //     const { id, key, align } = col;

                  //     if (key === 'name') {
                  //       return (
                  //         <TableCell
                  //           key={index}
                  //           component="th"
                  //           scope="row"
                  //           padding="0 2"
                  //           spacing={2}
                  //         >
                  //           <Stack direction="row" alignItems="center" spacing={2}>
                  //             <Avatar alt={name} src={name} />
                  //             <Typography variant="subtitle2" noWrap>
                  //               {/* <Link to={`/contact/${_id}/list`}> */}
                  //               {name}
                  //               {/* </Link> */}
                  //             </Typography>
                  //           </Stack>
                  //         </TableCell>
                  //       );
                  //     }
                  //     // storagePlanInfo
                  //     if (key === 'active') {
                  //       return (
                  //         <TableCell key={index} align="left">
                  //           <Label
                  //             variant="ghost"
                  //             color={(row[key] === false && 'error') || 'success'}
                  //           >
                  //             {row[key] === false ? 'Banned' : 'Active'}
                  //           </Label>
                  //         </TableCell>
                  //       );
                  //     }
                  //     if (key === 'createdAt') {
                  //       // return <TableCell align={align}>{row[key]}</TableCell>;
                  //       return (
                  //         <TableCell key={index} align={align}>
                  //           {fDate(row[key])}
                  //         </TableCell>
                  //       );
                  //     }
                  //     return key === 'action' ? (
                  //       <TableCell key={index} align={align}>
                  //         <MoreMenu contactId={_id} index={index} />
                  //       </TableCell>
                  //     ) : (
                  //       <TableCell align={align}>{row[key]}</TableCell>
                  //     );
                  //   })}
                  // </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {contactList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  {searchStatus ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={`${contactType} Contact Details`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
