import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useFormik, Form, FormikProvider } from 'formik';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import infoFill from '@iconify/icons-eva/info-fill';
// material
import {
  Link,
  Stack,
  FormHelperText,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Grid
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { codeSend, getTempToken, setNewPassword } from '../../appRedux/actions/auth';
import { showLoader } from '../../appRedux/actions/common';
import { resetPassword } from '../../appRedux/actions/member';
// import { Button } from 'antd';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 8, 0, 0),
  maxWidth: '600px',
  margin: 'auto'
}));
const GridMainStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 8, 0, 8),
  maxWidth: '600px',
  margin: 'auto'
}));

const DivStyleImage = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3),
  textAlign: 'center'
}));
const SpanStyle = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // -webkit-box-align: center;
  alignItems: 'center',
  color: 'rgb(99, 115, 129)',
  lineHeight: 1.5,
  fontSize: '0.75rem',
  fontWeight: 400,
  textAlign: 'left',
  margin: '4px 5px 0px'
}));
const INITIAL_STATE = {
  oldPassword: '',
  password: '',
  confirmNewPassword: ''
};
export default function ResetPassword({ subDomain }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  console.log({ subDomain });

  const [showPassword, setShowPassword] = useState(false);
  const { tempToken, type } = params;
  const { verifyType, code, loader, email } = useSelector((state) => ({
    verifyType: state.auth.verifyType,
    loader: state.common.loader,
    email: state.auth.email,
    code: state.auth.code
  }));

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const FormSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required.'),
    password: Yup.string()
      .required('Please enter your password.')
      .min(6, 'Your password is too short.'),
    confirmNewPassword: Yup.string()
      .required('Please confirm password your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      dispatch(showLoader());
      dispatch(
        resetPassword(
          {
            ...data,
            confirmNewPassword: undefined,
            tempToken,
            type: verifyType
          },
          subDomain
        )
      );
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;
  console.log({ errors, touched, values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <GridMainStyle container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Old Password"
              {...getFieldProps('oldPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            />
          </Grid>{' '}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <SpanStyle>
              <Icon icon={infoFill} height={14} />
              &nbsp; Password must be minimum 6 characters
            </SpanStyle>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Confirm New Password"
              {...getFieldProps('confirmNewPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />
          </Grid>
        </GridMainStyle>

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {'Update'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
