import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';

import { Icon } from '@iconify/react';
import arrowBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { ForgotPasswordForm } from '../components/authentication/forgotPassword';
import Logo from '../components/Logo';
import Snackbars from '../components/Snackbars';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(12, 0)
}));

const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}));
// ----------------------------------------------------------------------

export default function Login() {
  const subDomain = window.location.host.split('.')[0];
  // console.log({ location }, window.location.host.split('.')[0]);
  return (
    <RootStyle title="Login | TransferItNow Admin">
      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <RouterLinkStyle to="/">
            <Logo
              sx={{
                display: 'unset'
              }}
            />
          </RouterLinkStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h3" gutterBottom>
              Forgot your Password?
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Please enter the email address associated with your account and We will email you a
              link to reset your password.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <ForgotPasswordForm subDomain={subDomain} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
