import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useFormik, Form, FormikProvider } from 'formik';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import { Icon } from '@iconify/react';
import arrowBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, FormHelperText, Typography, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { codeSend, codeVerify, getTempToken } from '../../../appRedux/actions/auth';
import { showLoader } from '../../../appRedux/actions/common';
// import { Button } from 'antd';

// ----------------------------------------------------------------------

const ClickButton = styled('b')(({ theme }) => ({
  paddingLeft: '10px',
  color: theme.palette.primary.main,

  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  '&:hover': {
    // color: theme.palette.primary.main,
    backgroundColor: 'unset',
    textDecoration: 'underline'
  }
}));
const INITIAL_STATE = {
  otp: ''
};
export default function SetPasswordForm({ subDomain }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  console.log({ subDomain });

  const { tempToken, type } = params;
  const { verifyType, code, loader, email } = useSelector((state) => ({
    verifyType: state.auth.verifyType,
    loader: state.common.loader,
    email: state.auth.email,
    code: state.auth.code
  }));
  useEffect(() => {
    if (!tempToken) {
      navigate('/login', { replace: true });
    } else {
      dispatch(getTempToken(tempToken));
    }
    if (code)
      navigate(`/request-new-password-set/${code}/${tempToken}`, {
        replace: true
      });
  }, [tempToken, code]);

  const changeEmail = () => {
    // /request-password-reset
    // dispatch(tokenRemove());
    navigate('/forgot-password', { replace: true });
  };
  const FormSchema = Yup.object().shape({
    otp: Yup.string().min(6, 'Invalid code.').required('Code is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      dispatch(showLoader());
      dispatch(
        codeVerify(
          {
            code: data.otp,
            tempToken,
            type: verifyType
          },
          subDomain
        )
      );
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;
  console.log({ errors, touched, values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <MuiOtpInput
            sx={{ gap: 1 }}
            // onComplete={(finalValue) => {}}
            onComplete={(finalValue) => {
              const event = {
                target: {
                  name: 'otp',
                  value: finalValue
                }
              };
              handleChange(event);
            }}
            value={values.otp}
            onChange={(newValue) => {
              // handleChangeValue(value);
              const event = {
                target: {
                  name: 'otp',
                  value: newValue
                }
              };
              handleChange(event);
              handleBlur({ target: { name: 'otp' } });
            }}
            length={6}
          />
          <FormHelperText error={Boolean(touched.email && errors.email)}>
            {Boolean(touched.email && errors.email)}
          </FormHelperText>
        </Stack>

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loader}>
          Verify
        </LoadingButton>
        <Typography sx={{ padding: 2 }}>
          Don’t have a code?
          <ClickButton
            onClick={() =>
              dispatch(
                codeSend(
                  {
                    tempToken,
                    type: verifyType === 'SignUp' ? 'SignUpResendCode' : 'ResendCode'
                  },
                  subDomain
                )
              )
            }
          >
            Resend Code
          </ClickButton>
        </Typography>
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ my: 1 }}
        ></Stack>
        <ButtonStyle
          component={RouterLink}
          variant="subtitle2"
          to="/login"
          startIcon={<Icon icon={arrowBackFill} />}
        >
          Return to sign in
        </ButtonStyle>
      </Form>
    </FormikProvider>
  );
}
