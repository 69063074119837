import { useState } from 'react';
import { Upload } from 'antd';
import { Icon } from '@iconify/react';

import PlusOutlined from '@iconify/icons-ant-design/camera-outlined';
import LoadingOutlined from '@iconify/icons-ant-design/loading-outlined';

import { useSelector, useDispatch } from 'react-redux';
import { apiURL } from '../utils/config';
import { showMessageImage } from '../appRedux/actions/common';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default function Avatar({ imageUrl, setImageUrl, setImageUpdate }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ accessToken: state.auth.accessToken }));
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
        setImageUpdate(true);
      });
    }
  };

  const beforeUploads = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      dispatch(
        showMessageImage({
          authMemberMessage: 'You can only upload JPG/PNG file!',
          status: 404,
          error: true
        })
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      // message.error('Image must smaller than 3 MB!');
      dispatch(
        showMessageImage({
          authMemberMessage: 'Image must smaller than 3 MB!',
          status: 404,
          error: true
        })
      );
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div className="upload-icon">
      {loading ? (
        <Icon icon={LoadingOutlined} height={24} />
      ) : (
        //
        <Icon icon={PlusOutlined} height={24} />
      )}
      <p style={{}}>Upload Photo</p>
    </div>
  );
  const uploadButtonBefore = (
    <div className="upload-icon-before">
      {loading ? (
        <Icon icon={LoadingOutlined} height={24} />
      ) : (
        //
        <Icon icon={PlusOutlined} height={24} />
      )}
      <p style={{}}>Upload Photo</p>
    </div>
  );

  return (
    <div id="imageCircle">
      <Upload
        headers={{
          Authorization: `Bearer ${accessToken}`
        }}
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={`${apiURL}/media`}
        beforeUpload={beforeUploads}
        onChange={handleChange}
      >
        {imageUrl ? (
          <>
            <img src={imageUrl} alt="avatar" />
            {uploadButton}
          </>
        ) : (
          uploadButtonBefore
        )}
      </Upload>
    </div>
  );
}
