import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Card, Stack, Container, Typography, Grid } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { FormView } from '../../components/_user';
import { getUserInfo } from '../../appRedux/actions/user';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3)
}));
const StyleText = styled('p')(({ theme }) => ({
  margin: '0px auto 16px',
  fontWeight: 400,
  fontSize: '0.9rem',
  lineHeight: 1.5,
  color: theme.palette.grey[500]
}));
// ----------------------------------------------------------------------
const iframe =
  '<iframe height="700" style="width: 100%;" scrolling="no" title="fx." src="https://transferitnow.com/history-view/64ecda3d2280d90ccbc8ee64/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhZG1pbkB0cmFuc2Zlcml0bm93LmNvbSIsImlhdCI6MTY5MzIxOTc0MSwiZXhwIjoxNjkzMjI2OTQxfQ.c_kc3ayNriuz_R5LsYUerv_58OYTy_DkPK1FQCrZ8s8?height=500&theme-id=0&default-tab=js,result" frameborder="no" allowtransparency="true" allowfullscreen="true">See the Pen <a href="https://codepen.io/ycw/pen/JqwbQw/">fx.</a> by ycw(<a href="https://codepen.io/ycw">@ycw</a>) on <a href="https://codepen.io">CodePen</a>.</iframe>';
// A function component to load Iframe

function Iframe({ transferId, token }) {
  const iframeLink = `<iframe height="500" style="width: 100%;" scrolling="no" title="fx." src="https://transferitnow.com/history-view/${transferId}/${token}?height=500&theme-id=0&default-tab=js,result" frameborder="no" allowtransparency="true" allowfullscreen="true"> </iframe>`;

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: transferId && token ? iframeLink : ''
      }}
    />
  );
}

export default function UserView() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  console.log({ params, location: window.location.host });
  const { transferId } = params;
  const { token } = useSelector((state) => ({
    token: state.auth.accessToken
  }));

  useEffect(() => {
    // dispatch(getUserInfo({ transferId }));
  }, [transferId]);
  return (
    <Page title="View User | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            View Information
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <CardStyle>
              <h4>Transfer Information</h4>
              <StyleText />
              <Iframe transferId={transferId} token={token} />
              {/* <FormView {...{ isInfo: true }} /> */}
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
