import { Grid, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { apiURL } from '../../../utils/config';

const PreviewFile = ({ file, width, height, tempFile, visible, galleryInfo }) => {
  console.log({ tempFile, galleryInfo }, '----', apiURL + galleryInfo.imageUrl);
  const [preview, setPreview] = React.useState(null);
  if (!tempFile && galleryInfo && galleryInfo._id) {
    return (
      <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
        <img
          src={apiURL + galleryInfo.imageUrl}
          className="preview"
          alt="Preview"
          visible={visible}
          width={width}
          height={height}
        />
      </Stack>
    );
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);

  function isFileImage(file) {
    return file && file.type.split('/')[0] === 'image';
  }

  reader.onload = () => {
    setPreview(isFileImage(file) ? reader.result : '/default.svg');
  };

  return (
    <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
      <img
        src={preview}
        className="preview"
        visible={visible}
        alt="Preview"
        width={width}
        height={height}
      />
    </Stack>
  );
};

export default PreviewFile;
