import {
  CONTACT_CREATE_SUCCESS,
  CONTACT_LIST,
  CONTACT_LIST_SUCCESS,
  CONTACT_INFO_SUCCESS,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_DELETE_SUCCESS,
  CONTACT_FILTER_HANDLING,
  CONTACT_SEARCH_NAME
} from '../actionTypes/contact';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';
import { authMemberSignOut } from './auth';
// import AllDataService from '../services/all.service';

import axiosHttp from '../services/axiosCommon';
// getContactList;

export const getAllContacts = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/contacts/list', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: CONTACT_LIST,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getContactList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/contacts', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error && data.status !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/contact/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: CONTACT_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getContactInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/contacts', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: CONTACT_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createContact = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.post('/contacts', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/contact/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: CONTACT_CREATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateContact = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put('/contacts', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/contact/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: CONTACT_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteContact = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/contacts', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/contact/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: CONTACT_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: CONTACT_SEARCH_NAME,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: CONTACT_FILTER_HANDLING,
    payload: info
  });
};
