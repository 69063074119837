import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import Logo from '../components/Logo';
//
import { MHidden } from '../components/@material-extend';

import Snackbars from '../components/Snackbars';
import CircularIndeterminate from '../components/CircularIndeterminate';
import { hideLoader, hideMessage } from '../appRedux/actions/common';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showMessage, messageStatus, url, loader, accessToken, authMember } = useSelector(
    (state) => ({
      showMessage: state.common.showMessage,
      messageStatus: state.common.messageStatus,
      message: state.common.message,
      loader: state.common.loader,
      url: state.common.initURL,
      accessToken: state.auth.accessToken,
      authMember: state.auth.authMember
    })
  );
  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());

    if (loader) dispatch(hideLoader());
    if (accessToken && authMember && authMember._id) navigate('/dashboard/app', { replace: true });
  }, [accessToken]);
  console.log(
    'authLayout-------props_App-----layout_one',
    accessToken && authMember && authMember._id
  );
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      {/* <CircularIndeterminate key={Math.random()} /> */}
      {/* <Snackbars key={Math.random()} /> */}

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
