import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TablePagination,
  Grid
} from '@material-ui/core';
// components
import Page from '../../components/Page';
import { ListToolbar, ListView } from '../../components/_contact';
//
import { getContactList } from '../../appRedux/actions/contact';
import { initURL } from '../../appRedux/actions/common';
import { CopyClipboard } from '../../components/copyToClipboard';

// ----------------------------------------------------------------------

export default function ContactList() {
  const dispatch = useDispatch();

  const { contactTypes, isUpdate, queryInfo, page, limit, totalRecords, authMember } = useSelector(
    (state) => ({
      contactTypes: state.contact.contactType,
      isUpdate: state.contact.isUpdate,
      queryInfo: state.contact.queryInfo,
      page: state.contact.queryInfo.page,
      limit: state.contact.queryInfo.limit,
      totalRecords: state.contact.totalRecords,
      loader: state.common.loader,
      authMember: state.auth.authMember
    })
  );

  useEffect(() => {
    if (isUpdate) {
      dispatch(initURL('#'));
      dispatch(getContactList({ ...queryInfo }));
    } else {
      dispatch(initURL('#'));
      dispatch(getContactList({ ...queryInfo, page: 0, limit: 10 }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getContactList({ ...queryInfo, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getContactList({ ...queryInfo, page: 0, limit: event.target.value }));
  };

  return (
    <Page title="Contact | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${contactTypes} Contact List`}
          </Typography>
          {/* {authMember && authMember.accessLevel !== 15 ? (
            <Button
              variant="contained"
              component={RouterLink}
              to="/contact/new"
              startIcon={<Icon icon={plusFill} />}
            >
              Create Contact
            </Button>
          ) : (
            ''
          )} */}
        </Stack>
        {authMember && authMember.accessLevel === 15 && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <CopyClipboard />
            </Grid>
          </Grid>
        )}
        <Card>
          <ListToolbar />

          <ListView />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {/* <CardView /> */}
      </Container>
    </Page>
  );
}
