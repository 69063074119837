import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundSelectAll from '@iconify/icons-ic/round-select-all';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
//
import Scrollbar from '../../Scrollbar';
import {
  getTemplateList,
  onChangeFilterHandling,
  onChangeSearchName
} from '../../../appRedux/actions/emailTemplate';

// ----------------------------------------------------------------------
const ButtonStyle = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(0.5)
  // backgroundColor: theme.palette.primary
}));

export const FILTER_CATEGORY_OPTIONS = ['All', 'Activated', 'Discontinue'];
// ----------------------------------------------------------------------

FilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onCloseFilter: PropTypes.func
};

export default function FilterSidebar({ isOpenFilter, onCloseFilter }) {
  const dispatch = useDispatch();
  const { templateList, queryInfo, templateTypes, columnHandling, column, columnMain } =
    useSelector((state) => ({
      templateList: state.emailTemplate.templateList,
      queryInfo: state.emailTemplate.queryInfo,
      page: state.emailTemplate.queryInfo.page,
      limit: state.emailTemplate.queryInfo.limit,
      templateTypes: state.emailTemplate.templateType,
      columnMain: state.emailTemplate.columnHandling,
      columnHandling: state.emailTemplate.columnHandling.filter(({ isFilter }) => isFilter),
      column: state.emailTemplate.columnHandling
        .filter(({ visibility, isFilter }) => visibility && isFilter)
        .map(({ id }) => id),
      loader: state.common.loader
      // let result = objArray.map(({ foo }) => foo)
    }));
  useEffect(() => {
    // dispatch(initURL('#'));
    // dispatch(getTemplateList({ ...queryInfo, page: 0, limit: 10 }));
  }, []);
  console.log({ templateTypes });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      columnHandling: column,
      templateType: templateTypes
    },
    onSubmit: ({ templateType, columnHandling }) => {
      const newState = columnMain.map((obj) =>
        columnHandling.includes(obj.id)
          ? { ...obj, visibility: true }
          : { ...obj, visibility: false }
      );
      console.log({ newState, columnMain, columnHandling });
      dispatch(onChangeFilterHandling({ columnHandling: newState, templateType }));
      if (templateType !== templateTypes)
        dispatch(getTemplateList({ ...queryInfo, page: 0, templateType }));
      onCloseFilter();
    }
  });
  const onResetFilter = () => {
    resetForm();
    // onCloseFilter();/
  };
  const { values, getFieldProps, handleChange, resetForm, handleSubmit } = formik;
  console.log({ values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Drawer
          anchor="right"
          open={isOpenFilter}
          onClose={onCloseFilter}
          PaperProps={{
            sx: { width: 280, border: 'none', overflow: 'hidden' }
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Filters
            </Typography>
            <IconButton onClick={onCloseFilter}>
              <Icon icon={closeFill} width={20} height={20} />
            </IconButton>
          </Stack>
          <Divider />
          <Scrollbar>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Column Handling
                </Typography>
                <FormGroup>
                  {columnHandling.map((row) => {
                    const { id, name, visibility } = row;
                    return (
                      <FormControlLabel
                        key={id}
                        control={
                          <Checkbox
                            {...getFieldProps('columnHandling')}
                            value={id}
                            checked={values.columnHandling.includes(id)}
                          />
                        }
                        label={id}
                      />
                    );
                  })}
                </FormGroup>
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Template List
                </Typography>
                <RadioGroup {...getFieldProps('templateType')}>
                  {FILTER_CATEGORY_OPTIONS.map((item) => (
                    <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                  ))}
                </RadioGroup>
              </div>
            </Stack>
          </Scrollbar>
          <Box sx={{ p: 3 }}>
            <ButtonStyle
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              onClick={handleSubmit}
              startIcon={<Icon icon={roundSelectAll} />}
            >
              Apply
            </ButtonStyle>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={onResetFilter}
              startIcon={<Icon icon={roundClearAll} />}
            >
              Reset All
              {/* Clear All */}
            </Button>
          </Box>
        </Drawer>
      </Form>
    </FormikProvider>
  );
}
