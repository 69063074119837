import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, TablePagination } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { ListToolbar, ListView } from '../../components/_transferHistory';
//
import { getTransferList } from '../../appRedux/actions/transferHistory';
import { initURL } from '../../appRedux/actions/common';

// ----------------------------------------------------------------------

export default function TransferHistory() {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const { transferType, isUpdate, queryInfo, page, limit, totalRecords, loader } = useSelector(
    (state) => ({
      transferType: state.transferHistory.transferType,
      isUpdate: state.transferHistory.isUpdate,
      queryInfo: state.transferHistory.queryInfo,
      page: state.transferHistory.queryInfo.page,
      limit: state.transferHistory.queryInfo.limit,
      totalRecords: state.transferHistory.totalRecords,
      loader: state.common.loader
    })
  );

  useEffect(() => {
    if (isUpdate) {
      dispatch(initURL('#'));
      dispatch(getTransferList({ ...queryInfo, userId, transferType }));
    } else {
      dispatch(initURL('#'));
      dispatch(getTransferList({ ...queryInfo, userId, page: 0, limit: 10, transferType }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getTransferList({ ...queryInfo, userId, page: newPage, transferType }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      getTransferList({ ...queryInfo, userId, page: 0, limit: event.target.value, transferType })
    );
  };

  return (
    <Page title="TransferHistory | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${transferType} Transfers List`}
          </Typography>
        </Stack>

        <Card>
          <ListToolbar />

          <ListView />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
