import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  //   SUCCESS_MESSAGE,
  INIT_URL,
  ON_SHOW_MESSAGE_IMAGE,
  ON_HIDE_MESSAGE_IMAGE
} from '../actionTypes/common';

export const initURL = (url) => async (dispatch) => {
  dispatch({
    type: INIT_URL,
    payload: url
  });
};

export const showLoader = () => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
};

export const hideLoader = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_LOADER
  });
};

export const showMessage = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_MESSAGE,
    payload: info
  });
};
export const hideMessage = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_MESSAGE
  });
};

export const showMessageImage = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_MESSAGE_IMAGE,
    payload: info
  });
};
export const hideMessageImage = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_MESSAGE_IMAGE
  });
};
