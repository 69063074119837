import { Grid, Stack, Typography } from '@material-ui/core';
import React from 'react';

const PreviewFile = ({ file, width, height, isUpdate, templateInfo }) => {
  const [preview, setPreview] = React.useState(null);
  if (isUpdate) {
    return (
      <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
        {/* <Grid item xs={12} sm={6} md={6}> */}
        <img src={'/default.svg'} className="preview" alt="Preview" width={width} height={height} />
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6} md={6}> */}
        <Typography sx={{ paddingTop: 1.5 }}>{templateInfo.name}.html</Typography>
        {/* </Grid> */}
      </Stack>
    );
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);

  function isFileImage(file) {
    return file && file.type.split('/')[0] === 'image';
  }

  reader.onload = () => {
    setPreview(isFileImage(file) ? reader.result : '/default.svg');
  };

  return (
    <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
      {/* <Grid item xs={12} sm={6} md={6}> */}
      <img src={preview} className="preview" alt="Preview" width={width} height={height} />
      {/* </Grid> */}
      {/* <Grid item xs={12} sm={6} md={6}> */}
      <Typography sx={{ paddingTop: 1.5 }}>{file.name}</Typography>
      {/* </Grid> */}
    </Stack>
  );
};

export default PreviewFile;
