//   column_Handling;
export const COLUMN_HANDLING = [
  {
    id: 'SendTo',
    key: 'sendTo',
    label: 'Send To',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Subject',
    key: 'subject',
    label: 'Subject',
    align: 'left',
    visibility: true,
    isFilter: true
  },

  {
    id: 'Downloaded',
    key: 'downloaded',
    label: 'Downloaded',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'SentAt',
    key: 'createdAt',
    label: 'Sent At',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Files',
    key: 'totalFiles',
    label: 'Files',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Size',
    key: 'totalSize',
    label: 'Size',
    align: 'center',
    visibility: true,
    isFilter: true
  },

  {
    id: 'Status',
    key: 'expireAt',
    label: 'Status',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];
