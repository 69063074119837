import {
  SIGNIN_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_AUTH_USER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
  AUTH_USER_UPDATE,
  CODE_VERIFY,
  SIGNUP_USER_SUCCESS
} from '../actionTypes/auth';

export const AUTH_INIT_STATE = {
  authLevel: 1,
  code: '',
  verifyType: '',
  authMember: JSON.parse(localStorage.getItem('authMember')) || {},
  accessToken: localStorage.getItem('accessToken'),
  routeLevel: JSON.parse(localStorage.getItem('authMember'))
    ? JSON.parse(localStorage.getItem('authMember')).accessLevel
    : 0 || 0
};

function AuthReducer(state = AUTH_INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER_UPDATE: {
      return {
        ...state,
        authMember: payload
      };
    }

    case AUTH_LEVEL: {
      return {
        ...state,
        authLevel: payload
      };
    }

    case SIGNUP_USER_SUCCESS: {
      // localStorage.setItem("tempEmail", payload.email);
      return {
        ...state,
        tempToken: payload.tempToken,
        email: payload.email,
        verifyType: payload.type
      };
    }
    case CODE_VERIFY: {
      console.log({ payload });
      return {
        ...state,
        // tempToken: payload.tempToken,
        code: payload.code,
        email: null,
        verifyType: payload.type
      };
    }
    // case TOKEN_REMOVE: {
    //   localStorage.removeItem('tempEmail');
    //   return {
    //     ...state,
    //     email: null,
    //     tempToken: null,
    //     verifyType: null
    //   };
    // }
    case SIGNIN_USER_SUCCESS: {
      state.initURL = '/'; // state.authMember && state.authMember.trainee? "/trainee":"/main/dashboard/crm"
      console.log({ payload });
      return {
        ...state,
        authMember: payload.data,
        accessToken: payload.authToken,
        routeLevel: payload.data.accessLevel,
        verifyType: '',
        email: '',
        tempToken: ''
      };
    }

    case SIGNIN_USER_ERROR: {
      console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: 'error',
        message: payload.memberMessage,
        status: payload.status,
        errorMessage: payload.memberMessage
      };
    }

    case SIGNOUT_AUTH_USER_SUCCESS: {
      console.log({ payload, SIGNOUT_AUTH_USER_SUCCESS: 'SIGNOUT_AUTH_USER_SUCCESS' });
      return {
        ...state,
        authMember: {},
        accessToken: null
      };
    }

    case ALL_CLEAR_SUCCESS: {
      console.log({ payload, SIGNOUT_AUTH_USER_SUCCESS: 'SIGNOUT_AUTH_USER_SUCCESS' });
      return {
        ...state,
        authMember: {},
        accessToken: null,
        routeLevel: 0
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
