import React, { useState, useEffect } from 'react';
// import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

import { Button, Grid, Card } from '@material-ui/core';
import htmlToDraft from 'html-to-draftjs';
import { ContentState as DraftContentState, EditorState, Modifier } from 'draft-js';
import { apiURL } from '../utils/config';

import axiosAPI from '../appRedux/services/axiosCommon';
import SetInnerHTML from './SetInnerHTML';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function TextEditer({ actStep, overView, buttonText, onChangeContent }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (overView) {
      console.log({ overView });
      const contentBlock = htmlToDraft(overView);
      if (contentBlock) {
        setContentState(overView);
        setEditorState(
          EditorState.createWithContent(
            DraftContentState.createFromBlockArray(contentBlock.contentBlocks)
          )
        );
      }
    }
  }, [overView]);

  const { accessToken } = useSelector((state) => ({
    accessToken: state.auth.accessToken
  }));
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    console.log('onEditorStateChange----dataOne---', editorState);
  };
  const handleClick = () => {
    setShowContent(!showContent);
  };
  const onContentStateChange = (contentState) => {
    setContentState(draftToHtml(contentState));
    onChangeContent(draftToHtml(contentState));
    console.log('onContentStateChange----dataOne---', contentState);
  };

  function uploadCallbackFun(file) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file);
      axiosAPI
        .post(`${apiURL}/media`, data)
        .then(({ data }) => {
          console.log({ data: `${apiURL}${data.data}` });
          resolve({ data: { link: `${apiURL}${data.data}` } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  return (
    <div className="editor" spacing={(2, 0, 0, 0)}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onContentStateChange={onContentStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          // textAlign: { inDropdown: true },
          link: { inDropdown: true },
          //   history: { inDropdown: true },
          //   image: false,
          //   image: {
          //     visible: false
          //   }
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            // uploadCallback: (e) => {
            //   console.log({ e });
            // },
            className: 'new-class-one',
            popupClassName: 'img-demo-one',
            alignmentEnabled: true,
            uploadCallback: uploadCallbackFun,
            previewImage: true,
            defaultSize: {
              height: 'auto',
              width: 'auto'
            },
            alt: { present: true, mandatory: true }
          }
        }}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        spacing={(4, 0, 0, 0)}
        // spacing(4, 0, 0, 0)
        style={{ margin: '10px 0px ' }}
        disabled={!contentState}
      >
        {`${showContent ? 'Hide' : 'Show'} ${buttonText}`}{' '}
      </Button>
      {showContent && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Card spacing={4} style={{ padding: '15px' }}>
              <iframe
                title="my-iframe"
                width={'100%'}
                height="500px"
                style={{ border: 'unset ' }}
                srcDoc={contentState}
              ></iframe>
              {/* <SetInnerHTML innerHTML={contentState} /> */}
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
