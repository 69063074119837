import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// routes
// import { SnackbarProvider, useSnackbar } from 'notistack';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Snackbars from './components/Snackbars';

// import AlertMassage from './AlertMassage';
// ----------------------------------------------------------------------
import './ant-custom.scss';

// import { onPing } from "../appRedux/actions/Auth";

function App({ accessToken, authMember, component: Component, ...rest }, props) {
  console.log('props_App', props, { authMember, accessToken });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // if (!accessToken || accessToken === null) navigate('/login', { replace: true });
  // dispatch(onPing());
  return (
    <ThemeConfig>
      <ScrollToTop />
      {/* {show && <Snackbars key={Math.random()} message="Success" />} */}
      {/* <SnackbarProvider> */}
      <Snackbars key={Math.random()} />
      <Router />
      {/* </SnackbarProvider> */}
    </ThemeConfig>
  );
}

const mapStatetoProps = (state) => {
  // console.log({ state });
  return { accessToken: state.auth.accessToken, authMember: state.auth.authMember };
};

export default connect(mapStatetoProps)(App);
