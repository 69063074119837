import {
  USER_CREATE_SUCCESS,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_INFO_SUCCESS,
  USER_FILTER_HANDLING,
  USER_SEARCH_NAME,
  USER_UPDATE_SUCCESS,
  USER_CODE,
  USER_SEARCH_CODE
} from '../actionTypes/user';

import { PLATFORM_TYPE, COLUMN_HANDLING } from '../../_mocks_/user';

const INIT_STATE = {
  users: [],
  userList: [],
  isSuccess: false,
  userInfo: {},
  queryInfo: {
    page: 0,
    limit: 10
  },
  totalRecords: 0,
  page: 0,
  columnHandling: COLUMN_HANDLING,
  searchName: '',
  searchStatus: false,
  userType: 'Activated',
  isUpdate: false,
  isUser: true,
  platformType: PLATFORM_TYPE,
  userCode: { searchText: '', code: '', message: '', status: false, messageStatus: 0 }
};

function userReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_CODE: {
      console.log({ payload });
      return {
        ...state,
        userCode: {
          ...state.userCode,
          message: payload.memberMessage,
          messageStatus: payload.status
        }
      };
    }
    case USER_SEARCH_CODE: {
      console.log({ payload });
      return {
        ...state,
        userCode: { ...state.userCode, code: payload, status: true }
      };
    }

    case USER_LIST: {
      console.log({ payload });
      return {
        ...state,
        users: payload,
        isUser: false
      };
    }
    case USER_LIST_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        userList: payload.data,
        queryInfo: {
          ...payload.queryInfo
        },
        totalRecords: payload.totalRecords,
        isSuccess: false
        // page: payload.queryInfo.page || 0
      };
    }

    case USER_CREATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        userList: [payload.data, ...state.userList],
        isSuccess: true
      };
    }

    case USER_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        userList: state.userList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        userInfo: payload.data,
        isSuccess: true,
        isUpdate: true
      };
    }

    case USER_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        userInfo: payload.data
        // isSuccess: true
      };
    }
    case USER_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        userType: payload.userType
      };
    }
    case USER_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit }
      };
    }

    default:
      return state;
  }
}

export default userReducer;
