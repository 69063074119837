import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { v4 as uuid } from 'uuid';
import { CountryList } from '../../utils/constant';
import { createUser, updateUser } from '../../appRedux/actions/user';
import { showLoader } from '../../appRedux/actions/common';
import VloggingPlatform from './VloggingPlatform';
import { CustomCheckbox, ImageUpload, UserCode } from '.';

// ----------------------------------------------------------------------

const DivStyleImage = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3),
  textAlign: 'center'
}));
const SpanStyle = styled('span')(({ theme }) => ({
  margin: '16px auto 0px',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.5,
  color: 'rgb(99, 115, 129)',
  display: 'block',
  textAlign: 'center'
}));
const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 0, 0)
}));
const initialValue = {
  name: '',
  userCode: '',
  whatsAppNumber: '',
  email: '',
  contactNumber: '',
  country: 'India',
  city: '',
  postalCode: '',
  address: '',
  campaignName: {},
  platformList: [{ id: uuid(), type: '', subType: '', url: '', active: true }]
};

FormView.propTypes = {
  isUpdate: PropTypes.bool,
  isInfo: PropTypes.bool
};

const filterByReference = (list1, list2) => {
  console.log({ list1, list2 }, 'info');
  // let res = [];
  const res = list1
    .filter((el) => list2.some((f) => f.title === el.subType))
    .map((item) => ({
      ...item,
      type: list2.find((f) => f.title === item.subType).type
    }));
  return res;
};

export default function FormView({ isUpdate, isInfo }) {
  // ----------------------------------------------------------------------
  const { userId } = useParams();
  // console.log({ params });
  const dispach = useDispatch();
  const navigate = useNavigate();
  const [viewOnly, setViewOnly] = useState(isInfo && userId ? true : false);
  const [imageUrl, setImageUrl] = useState('');
  const { isSuccess, userInfo, platformType, userCode, messageStatus, message, url, loader } =
    useSelector((state) => ({
      isSuccess: state.user.isSuccess,
      userInfo: state.user.userInfo,
      platformType: state.user.platformType,
      userCode: state.user.userCode.code,
      messageStatus: state.user.userCode.messageStatus,
      message: state.user.userCode.message,
      // messageStatus: state.common.messageStatus,
      url: state.common.initURL,
      loader: state.common.loader
    }));
  console.log({ userInfo, platformType });
  useEffect(() => {
    if (url === '/user/list' && isSuccess) {
      const timeout = setTimeout(() => {
        navigate(url, { replace: true });
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isUpdate || viewOnly) setActive(userInfo.active);
  }, [url, isSuccess, userInfo, isUpdate, viewOnly, navigate]);

  useEffect(() => {
    if ((isUpdate || isInfo) && userInfo && userInfo.profileDpUrl !== '') {
      setImageUrl(userInfo.profileDpUrl);
    } else {
      setImageUrl('');
    }
  }, [userInfo]);
  const [deleted, setDeleted] = React.useState(false);

  const [active, setActive] = useState(isUpdate || viewOnly ? userInfo.active : true);
  const [userList, setUserList] = useState([]);

  const Schema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('User Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    whatsAppNumber: Yup.string(),
    contactNumber: Yup.string().min(8, 'Too Short!').required('Contact Number is required'),
    address: Yup.string().min(4, 'Too Short!').required('Address is required'),
    postalCode: Yup.string().min(5, 'Too Short!').required('Pin Code is required'),
    userCode: Yup.string().required('User Code is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    platformList: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(),
        subType: Yup.string().required('Platform Type is required'),
        url: Yup.string().url('URL is not valid').required('URL Address is required')
      })
    ),
    campaignName: Yup.lazy((value) => {
      let yupStatus = false;
      Object.keys(value).forEach((key) => {
        if (value[key].status) {
          yupStatus = true;
        }
      });
      if (yupStatus) {
        return Yup.mixed().notRequired();
      }
      const validationObject = {
        status: Yup.mixed().required('Select at least One User Type.')
      };
      return Yup.object(validationObject);
    })
  });
  const initValues = userInfo && userInfo._id && (isUpdate || viewOnly) ? userInfo : initialValue;

  const formik = useFormik({
    enableReinitialize: isUpdate || viewOnly || false,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      if (!viewOnly) {
        const { platformList } = data;
        const infoList = filterByReference(platformList, userList);
        console.log({ infoList });
        dispach(showLoader());
        if (isUpdate)
          dispach(
            updateUser({
              ...data,
              platformList: infoList,
              _id: undefined,
              userId: userInfo._id,
              profileDpUrl: imageUrl,
              active,
              emailType: 'user',
              emailSubType: 'registration'
            })
          );
        else
          dispach(
            createUser({
              ...data,
              platformList: infoList,
              profileDpUrl: imageUrl,
              emailType: 'user',
              emailSubType: 'registration'
            })
          );
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError } =
    formik;
  useEffect(() => {
    console.log({ platformType, name: values.campaignName });
    if (values.campaignName) {
      const myArrayFiltered = platformType.filter((el) =>
        Object.keys(values.campaignName).some((f) => f === el.type && values.campaignName[f].status)
      );
      setUserList(myArrayFiltered);
      const platformLists = values.platformList;
      const newList = platformLists.map((info) => {
        if (
          values.campaignName &&
          values.campaignName[info.type] &&
          !values.campaignName[info.type].status
        ) {
          info.active = false;
        } else {
          info.active = true;
        }
        return info;
      });
      setFieldValue('platformList', newList);
    }
  }, [values.campaignName, platformType]);

  useEffect(() => {
    if ((!isUpdate || !viewOnly) && messageStatus === 200) {
      setFieldValue('userCode', userCode);
    } else if (messageStatus === 409) {
      setFieldValue('userCode', '');
      setFieldError('userCode', message);
    } else {
      setFieldValue('userCode', message);
    }
  }, [userCode, messageStatus, message, isUpdate, viewOnly]);
  useEffect(() => {
    let status = false;
    const platformLists = values.platformList;
    if (platformLists.length > 1) {
      status = true;
    }
    setDeleted(status);
  }, [values.platformList]);
  // [...new Map(platformType.map((item) => [item.type, item])).values()].map((item) =>
  //   console.log(values.campaignName[item.type].status, '----campaignName------')
  // );
  console.log({ values, userList });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} md={4}>
            <DivStyleImage>
              <ImageUpload imageUrl={imageUrl} setImageUrl={setImageUrl} />
              <SpanStyle>
                Allowed *.jpeg, *.jpg, *.png, *.gif
                <br /> Max Size of 3MB
              </SpanStyle>
            </DivStyleImage>
          </Grid>

          <Grid item xs={12} sm={7} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  fullWidth
                  label="User Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                {isUpdate || viewOnly ? (
                  <TextField
                    fullWidth
                    label="User Code"
                    {...getFieldProps('userCode')}
                    error={Boolean(touched.userCode && errors.userCode)}
                    helperText={touched.userCode && errors.userCode}
                    disabled={viewOnly || isUpdate}
                  />
                ) : (
                  <UserCode values={values} errors={errors} touched={touched} viewOnly={viewOnly} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email Address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Contact Number"
                  {...getFieldProps('contactNumber')}
                  error={Boolean(touched.contactNumber && errors.contactNumber)}
                  helperText={touched.contactNumber && errors.contactNumber}
                  disabled={viewOnly}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  type="whatsAppNumber"
                  label="WhatsApp Number"
                  {...getFieldProps('whatsAppNumber')}
                  error={Boolean(touched.whatsAppNumber && errors.whatsAppNumber)}
                  helperText={touched.whatsAppNumber && errors.whatsAppNumber}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Country"
                    {...getFieldProps('country')}
                    error={Boolean(touched.country && errors.country)}
                    disabled={viewOnly}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {CountryList.map((list) => (
                      <MenuItem key={list.name} value={list.name}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.country && errors.country}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  label="City"
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Pin Code"
                  {...getFieldProps('postalCode')}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                  disabled={viewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                error={touched.campaignName && errors.campaignName && errors.campaignName.status}
              >
                User Type
              </FormLabel>
              <FormGroup aria-label="position" row>
                {[...new Map(platformType.map((item) => [item.type, item])).values()].map(
                  (item) => (
                    <CustomCheckbox
                      platformType={platformType}
                      item={item}
                      formik={formik}
                      viewOnly={viewOnly}
                    />
                  )
                )}
              </FormGroup>
            </FormControl>
            <FormHelperText
              error={touched.campaignName && errors.campaignName && errors.campaignName.status}
            >
              {touched.campaignName && errors.campaignName && errors.campaignName.status}
            </FormHelperText>
          </Grid>
          {userList && userList.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <VloggingPlatform
                formik={formik}
                userList={userList}
                deleted={deleted}
                viewOnly={viewOnly}
              />
            </Grid>
          )}
        </Grid>

        {!viewOnly && (
          <GridStyle container spacing={3}>
            <Grid item xs={12} sm={6} md={9} />
            <Grid item xs={12} sm={6} md={3}>
              <LoadingButton
                fullWidth
                // size="large"
                type="submit"
                variant="contained"
                loading={loader}
              >
                {isUpdate ? 'Update User' : ' Create User'}
              </LoadingButton>
            </Grid>
          </GridStyle>
        )}
      </Form>
    </FormikProvider>
  );
}
