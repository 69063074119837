import React from 'react';
import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

export const COLUMN_HANDLING = [
  {
    id: 'Name',
    key: 'name',
    label: 'Name',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  { id: 'Email', key: 'email', label: 'Email', align: 'left', visibility: true, isFilter: true },
  {
    id: 'ContactNumber',
    key: 'contactNumber',
    label: 'Contact Number',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  // {
  //   id: 'IdNumber',
  //   key: 'idNumber',
  //   label: 'ID Number',
  //   align: 'left',
  //   visibility: false,
  //   isFilter: true
  // },
  {
    id: 'Country',
    key: 'country',
    label: 'Country',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  { id: 'City', key: 'city', label: 'City', align: 'left', visibility: true, isFilter: true },
  {
    id: 'Address',
    key: 'address',
    label: 'Address',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'PostalCode',
    key: 'postalCode',
    label: 'Pin Code',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'WhatsAppNumber',
    key: 'whatsAppNumber',
    label: 'WhatsApp Number',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Status',
    key: 'active',
    label: 'Status',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'CreatedAt',
    key: 'createdAt',
    label: 'Created At',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];
export const MEMBERLIST = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.findName(),
  company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer'
  ])
}));

// export default admins;
