import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment, { months } from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { v4 as uuid } from 'uuid';
import { CountryList } from '../../utils/constant';
import { createMember, updateMember, updateMemberProfile } from '../../appRedux/actions/member';
import { showLoader } from '../../appRedux/actions/common';
import { ImageUpload } from '.';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0, 0)
}));

const DivStyleImage = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3),
  textAlign: 'center'
}));
const SpanStyle = styled('span')(({ theme }) => ({
  margin: '16px auto 0px',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.5,
  color: 'rgb(99, 115, 129)',
  display: 'block',
  textAlign: 'center'
}));
const initialValue = {
  name: '',
  whatsAppNumber: '',
  email: '',
  contactNumber: '',
  country: 'India',
  city: '',
  postalCode: '',
  address: ''
};

FormView.propTypes = {
  isUpdate: PropTypes.bool,
  isInfo: PropTypes.bool
};

export default function FormView({ subDomain }) {
  // ----------------------------------------------------------------------

  const dispach = useDispatch();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');
  const [imageUpdate, setImageUpdate] = useState(false);
  const isUpdate = true;
  const { isSuccess, memberInfo, url, loader } = useSelector((state) => ({
    isSuccess: state.member.isSuccess,
    memberInfo: state.authMember.authMember,
    messageStatus: state.common.messageStatus,
    url: state.common.initURL,
    loader: state.common.loader
  }));
  useEffect(() => {
    if (memberInfo && memberInfo.profileDpUrl !== '') {
      setImageUrl(memberInfo.profileDpUrl);
    } else {
      setImageUrl('');
    }
  }, [memberInfo]);

  const Schema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    whatsAppNumber: Yup.string(),
    contactNumber: Yup.string().min(8, 'Too Short!').required('Contact number is required'),
    address: Yup.string().min(4, 'Too Short!').required('Address is required'),
    postalCode: Yup.string().min(5, 'Too Short!').required('Postal code is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required')
  });
  const initValues = memberInfo && memberInfo._id && isUpdate ? memberInfo : initialValue;

  const formik = useFormik({
    enableReinitialize: isUpdate,

    initialValues: initValues,
    validationSchema: Schema,
    onSubmit: (data) => {
      dispach(showLoader());
      dispach(
        updateMemberProfile(
          {
            ...data,
            _id: undefined,
            memberId: memberInfo._id,
            // profileDpUrl: imageUrl,
            active: true
          },
          subDomain
        )
      );
    }
  });

  useEffect(() => {
    console.log({
      memberInfo,
      imageUrl,
      imageUpdate,
      status: imageUpdate && memberInfo && imageUrl !== memberInfo.profileDpUrl
    });
    if (imageUpdate && memberInfo && imageUrl !== memberInfo.profileDpUrl) {
      dispach(showLoader());
      dispach(
        updateMemberProfile(
          {
            ...memberInfo,
            memberId: memberInfo._id,
            _id: undefined,
            profileDpUrl: imageUrl,
            active: true
          },
          subDomain
        )
      );
      setImageUpdate(false);
    }
  }, [imageUrl, imageUpdate]);
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  console.log({ values });
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} md={4}>
            <DivStyleImage>
              <ImageUpload
                imageUrl={imageUrl}
                setImageUpdate={setImageUpdate}
                setImageUrl={setImageUrl}
              />
              <SpanStyle>
                Allowed *.jpeg, *.jpg, *.png, *.gif
                <br /> Max Size of 3MB
              </SpanStyle>
            </DivStyleImage>
          </Grid>

          <Grid item xs={12} sm={7} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  fullWidth
                  label="Member Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  type="whatsAppNumber"
                  label="WhatsApp Number"
                  {...getFieldProps('whatsAppNumber')}
                  error={Boolean(touched.whatsAppNumber && errors.whatsAppNumber)}
                  helperText={touched.whatsAppNumber && errors.whatsAppNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email Address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  required
                  disabled
                  readonly
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Contact Number"
                  {...getFieldProps('contactNumber')}
                  error={Boolean(touched.contactNumber && errors.contactNumber)}
                  helperText={touched.contactNumber && errors.contactNumber}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label" required>
                    Country
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Country"
                    {...getFieldProps('country')}
                    error={Boolean(touched.country && errors.country)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {CountryList.map((list) => (
                      <MenuItem key={list.name} value={list.name}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.country && errors.country}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  label="City"
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Pin Code"
                  {...getFieldProps('postalCode')}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <GridStyle container spacing={3}>
          <Grid item xs={12} sm={6} md={9} />
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
              fullWidth
              // size="large"
              type="submit"
              variant="contained"
              loading={loader}
            >
              {'Update'}
            </LoadingButton>
          </Grid>
        </GridStyle>
      </Form>
    </FormikProvider>
  );
}
