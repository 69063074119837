import {
  USER_CREATE_SUCCESS,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_INFO_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_FILTER_HANDLING,
  USER_SEARCH_NAME,
  USER_CODE,
  USER_SEARCH_CODE,
  USER_ACTION_SUCCESS
} from '../actionTypes/user';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';
import { authMemberSignOut } from './auth';
// import AllDataService from '../services/all.service';

import axiosHttp from '../services/axiosCommon';
// getUserList;

export const getAllUsers = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/user/list', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: USER_LIST,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getUserCode = (queryInfo) => async (dispatch) => {
  try {
    const result = await axiosHttp.get('/user/code', {
      params: queryInfo
    });
    const { data } = result;

    if (data.error && data.status !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: USER_CODE,
        payload: { ...data }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getUserList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/user', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      if (data.status === 200) {
        dispatch({
          type: INIT_URL,
          payload: '/user/list'
        });
        dispatch({
          type: USER_LIST_SUCCESS,
          payload: { ...data, queryInfo }
        });
      }
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/user/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getUserInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/user', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: USER_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createUser = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.post('/user', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/user/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateUser = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put('/user', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/user/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteUser = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/user', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/user/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const actionUser = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  // userId, active: !status, queryInfo
  const { queryInfo } = infoData;
  try {
    const result = await axiosHttp.put('/user/action', { ...infoData, queryInfo: undefined });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/user/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch(getUserList({ ...queryInfo }));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: USER_SEARCH_NAME,
    payload: info
  });
};
export const onChangeSearchCode = (info) => async (dispatch) => {
  console.log({ info });
  dispatch({
    type: USER_SEARCH_CODE,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: USER_FILTER_HANDLING,
    payload: info
  });
};
