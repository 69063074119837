import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer
} from '@material-ui/core';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import ResultNotFound from '../../ResultNotFound';
import { ListHead, MoreMenu } from '.';
//
import { fDate } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

export default function ListView() {
  const dispatch = useDispatch();

  const {
    searchStatus,
    searchName,
    templateList,
    templateType,
    queryInfo,
    page,
    limit,
    columnHandling,
    loader
  } = useSelector((state) => ({
    templateType: state.emailTemplate.templateType,
    searchStatus: state.emailTemplate.searchStatus,
    searchName: state.emailTemplate.searchName,
    templateList: state.emailTemplate.templateList,
    queryInfo: state.emailTemplate.queryInfo,
    page: state.emailTemplate.queryInfo.page,
    limit: state.emailTemplate.queryInfo.limit,
    columnHandling: state.emailTemplate.columnHandling.filter(
      (item) => item.visibility || !item.isFilter
    ),
    loader: state.common.loader
  }));

  const emptyRows = page > 0 ? Math.max(0, limit - templateList.length) : 0;
  console.log({ emptyRows, emptyRows2: limit, length: templateList.length });
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ListHead headLabel={columnHandling} checkbox={false} isSortLabel={false} />
          <TableBody>
            {templateList &&
              templateList.length > 0 &&
              templateList.map((row) => {
                const { _id, active, name } = row;
                return (
                  <TableRow hover key={_id}>
                    {columnHandling.map((col, index) => {
                      const { id, key, align } = col;

                      if (key === 'name') {
                        return (
                          <TableCell component="th" scope="row" padding="0 2" spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={name} />
                              <Typography variant="subtitle2" noWrap>
                                <Link to={`/settings/email-template/${_id}/info`}>{name}</Link>
                              </Typography>
                            </Stack>
                          </TableCell>
                        );
                      }
                      if (key === 'active') {
                        return (
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(row[key] === false && 'error') || 'success'}
                            >
                              {row[key] === false ? 'Banned' : 'Active'}
                            </Label>
                          </TableCell>
                        );
                      }
                      if (key === 'createdAt') {
                        // return <TableCell align={align}>{row[key]}</TableCell>;
                        return <TableCell align={align}>{fDate(row[key])}</TableCell>;
                      }
                      return key === 'action' ? (
                        <TableCell align={align}>
                          <MoreMenu
                            active={active}
                            templateId={_id}
                            index={index}
                            queryInfo={queryInfo}
                          />
                        </TableCell>
                      ) : (
                        <TableCell align={align}>{row[key]}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {templateList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  {searchStatus ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={`${templateType} Template`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
