import {
  CONTACT_DETAILS_CREATE_SUCCESS,
  CONTACT_DETAILS_LIST,
  CONTACT_DETAILS_LIST_SUCCESS,
  CONTACT_DETAILS_INFO_SUCCESS,
  CONTACT_DETAILS_FILTER_HANDLING,
  CONTACT_DETAILS_SEARCH_NAME,
  CONTACT_DETAILS_UPDATE_SUCCESS
} from '../actionTypes/contactDetails';

import { COLUMN_HANDLING } from '../../_mocks_/contactDetails';

const INIT_STATE = {
  contacts: [],
  contactList: [],
  isSuccess: false,
  contactInfo: {},
  queryInfo: {
    page: 0,
    limit: 30
  },
  totalRecords: 0,
  page: 0,
  columnHandling: COLUMN_HANDLING,
  searchName: '',
  searchStatus: false,
  contactType: 'Active',
  isUpdate: false,
  isContact: true
};
// subject: 1,
// message: 1,
function contactReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_DETAILS_LIST: {
      console.log({ payload });
      return {
        ...state,
        contacts: payload,
        isContact: false
      };
    }

    case CONTACT_DETAILS_LIST_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        contactList: payload.data,
        queryInfo: {
          ...payload.queryInfo
        },
        totalRecords: payload.totalRecords,
        isSuccess: false
        // page: payload.queryInfo.page || 0
      };
    }
    case CONTACT_DETAILS_CREATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        contactList: [payload.data, ...state.contactList],
        isSuccess: true
      };
    }

    case CONTACT_DETAILS_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        contactList: state.contactList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        contactInfo: payload.data,
        isSuccess: true,
        isUpdate: true
      };
    }

    case CONTACT_DETAILS_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        contactInfo: payload.data
        // isSuccess: true
      };
    }
    case CONTACT_DETAILS_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        contactType: payload.contactType
      };
    }
    case CONTACT_DETAILS_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit }
      };
    }

    default:
      return state;
  }
}

export default contactReducer;
