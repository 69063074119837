import React, { useState, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Card,
  Button,
  Typography,
  Stack,
  IconButton,
  Snackbar,
  CloseIcon
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import copy2Fill from '@iconify/icons-mingcute/copy-2-fill';
import copy2Line from '@iconify/icons-mingcute/copy-2-line';
// material

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(0.5, 2),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));
const TypographyH3Style = styled('h3')(({ theme }) => ({
  boxShadow: 'none',
  letterSpacing: '1px',
  textAlign: 'center',
  textTransform: 'capitalize',
  padding: theme.spacing(1, 0)
}));
const SpanStyle = styled('span')(({ theme }) => ({
  textTransform: 'lowercase',
  padding: theme.spacing(0, 2)
}));
const TypographyStyle = styled('h3')(({ theme }) => ({
  boxShadow: 'none',
  letterSpacing: '1px',
  opacity: 0.72,
  textTransform: 'capitalize',
  padding: theme.spacing(1, 2)
}));
export const CopyClipboard = () => {
  const [copied, setCopied] = useState(false);

  const { authMember, userCode } = useSelector((state) => ({
    authMember: state.auth.authMember,
    userCode:
      state.auth.authMember && state.auth.authMember._id ? state.auth.authMember.userCode : ''
  }));
  const [valueUrl, setValueUrl] = useState(`http://play.gaviral.in/${userCode}`);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const otherCopy = () => {
    setCopied(true);
    setOpen(true);
  };
  const notCopy = () => setCopied(false);

  return (
    <>
      <RootStyle>
        <Stack
          direction="row"
          spacing={{ xs: 0.5, sm: 1.5 }}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <TypographyH3Style variant="subtitle2">
            {`Copy your user link -`}
            <SpanStyle sx={{}}>{valueUrl}</SpanStyle>
          </TypographyH3Style>
          <TypographyH3Style
            variant="subtitle2"
            sx={{ alignItems: 'end', cursor: 'pointer' }}
            onMouseEnter={() => notCopy()}
          >
            <CopyToClipboard onCopy={otherCopy} text={valueUrl}>
              <IconButton aria-label="close">
                <Icon icon={!copied ? copy2Line : copy2Fill} />
              </IconButton>
            </CopyToClipboard>
          </TypographyH3Style>
        </Stack>
      </RootStyle>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={600}
        onClose={handleClose}
        message="Copied."
      />
    </>
  );
};
