export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const USER_INFO = 'USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_FILTER_HANDLING = 'USER_FILTER_HANDLING';
export const USER_SEARCH_NAME = 'USER_SEARCH_NAME';
export const USER_CODE = 'USER_CODE';
export const USER_SEARCH_CODE = 'USER_SEARCH_CODE';
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';
