import { combineReducers } from 'redux';
import member from './member';
import auth from './auth';
import common from './common';
import associate from './associate';
import dashboard from './dashboard';
import contact from './contact';
import contactDetails from './contactDetails';
import imageGallery from './imageGallery';
import emailSetting from './emailSetting';
import emailTemplate from './emailTemplate';
import transferHistory from './transferHistory';
import user from './user';
import authMember from './authMember';

export default combineReducers({
  member,
  dashboard,
  associate,
  auth,
  common,
  contact,
  contactDetails,
  imageGallery,
  emailSetting,
  emailTemplate,
  transferHistory,
  user,
  authMember
});
