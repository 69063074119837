import React, { useState } from 'react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Card, Stack, Container, Typography, Grid } from '@material-ui/core';
// components
import Page from '../../components/Page';

import { FormView, ImageUpload } from '../../components/_associate';
// import { Image } from '../../components/_admin';
// ----------------------------------------------------------------------

const CardStyleImage = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5, 3),
  textAlign: 'center'
}));
const CardStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3)
}));
const StyleText = styled('p')(({ theme }) => ({
  margin: '0px auto 16px',
  fontWeight: 400,
  fontSize: '0.9rem',
  lineHeight: 1.5,
  color: theme.palette.grey[500]
}));
// ----------------------------------------------------------------------

export default function Associate() {
  return (
    <Page title="Associate | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create New Associate
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <CardStyle>
              <h4>Registration Associate</h4>
              <StyleText />
              <FormView {...{ isUpdate: false }} />
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
