import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, TablePagination } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { ListToolbar, ListView } from '../../components/_associate';
//
import { getAssociateList } from '../../appRedux/actions/associate';
import { initURL } from '../../appRedux/actions/common';

// ----------------------------------------------------------------------

export default function Associate() {
  const dispatch = useDispatch();

  const { associateTypes, isUpdate, queryInfo, page, limit, totalRecords, loader } = useSelector(
    (state) => ({
      associateTypes: state.associate.associateType,
      isUpdate: state.associate.isUpdate,
      queryInfo: state.associate.queryInfo,
      page: state.associate.queryInfo.page,
      limit: state.associate.queryInfo.limit,
      totalRecords: state.associate.totalRecords,
      loader: state.common.loader
    })
  );

  useEffect(() => {
    if (isUpdate) {
      dispatch(initURL('#'));
      dispatch(getAssociateList({ ...queryInfo }));
    } else {
      dispatch(initURL('#'));
      dispatch(getAssociateList({ ...queryInfo, page: 0, limit: 10 }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getAssociateList({ ...queryInfo, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getAssociateList({ ...queryInfo, page: 0, limit: event.target.value }));
  };

  return (
    <Page title="Associate | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${associateTypes} Associate List`}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/associate/new"
            startIcon={<Icon icon={plusFill} />}
          >
            New Associate
          </Button>
        </Stack>

        <Card>
          <ListToolbar />

          <ListView />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
