import {
  MEMBER_CREATE_SUCCESS,
  MEMBER_LIST,
  MEMBER_LIST_SUCCESS,
  MEMBER_INFO_SUCCESS,
  MEMBER_UPDATE_SUCCESS,
  MEMBER_DELETE_SUCCESS,
  MEMBER_FILTER_HANDLING,
  MEMBER_SEARCH_NAME,
  MEMBER_ACTION_SUCCESS
} from '../actionTypes/member';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';
import { authMemberSignOut } from './auth';
// import AllDataService from '../services/all.service';

import axiosHttp from '../services/axiosCommon';
import { AUTH_USER_UPDATE } from '../actionTypes/auth';
// getMemberList;

export const getAllMembers = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/member/list', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: MEMBER_LIST,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getMemberList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/member', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      if (data.status === 200) {
        dispatch({
          type: MEMBER_LIST_SUCCESS,
          payload: { ...data, queryInfo }
        });
      }
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/member/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: MEMBER_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getMemberInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/member', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: MEMBER_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createMember = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.post('/member', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/member/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: MEMBER_CREATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateMember = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put('/member', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/member/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: MEMBER_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const updateMemberProfile = (infoData, type) => async (dispatch) => {
  const url = type === 'user' ? '/user' : '/member';
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put(url, infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/profile/view'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      localStorage.setItem('authMember', JSON.stringify(data.data));
      dispatch({
        type: AUTH_USER_UPDATE,
        payload: data.data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const resetPassword = (infoData, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/password-reset' : '/member/password-reset';
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put(url, infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteMember = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/member', infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/member/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: MEMBER_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const actionMember = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  const { queryInfo } = infoData;
  try {
    const result = await axiosHttp.put('/member/action', { ...infoData, queryInfo: undefined });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/member/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch(getMemberList({ ...queryInfo }));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: MEMBER_SEARCH_NAME,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: MEMBER_FILTER_HANDLING,
    payload: info
  });
};
