import React from 'react';
import { Icon } from '@iconify/react';
import settings5Fill from '@iconify/icons-mingcute/settings-5-fill';
import image2Fill from '@iconify/icons-eva/image-2-fill';
import atFill from '@iconify/icons-mingcute/at-fill';
import mailFill from '@iconify/icons-mingcute/mail-open-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Email Setting',
    path: '/settings/email-setting',
    accessLevel: [1, 2, 3, 4],
    icon: getIcon(atFill)
  },
  {
    title: 'image Gallery',
    path: '/settings/image-gallery',
    accessLevel: [1, 2, 3, 4],
    icon: getIcon(image2Fill),
    children: [
      { title: 'List ', path: '/settings/image-gallery/list' },
      { title: 'Create', path: '/settings/image-gallery/new' }
    ]
  },
  {
    title: 'mail template',
    path: '/settings/email-template',
    accessLevel: [1, 2, 3, 4],
    icon: getIcon(mailFill),
    children: [
      { title: 'List ', path: '/settings/email-template/list' },
      { title: 'Create', path: '/settings/email-template/new' }
    ]
  }
];

export default sidebarConfig;
