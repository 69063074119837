import {
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_INFO_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_DELETE_SUCCESS,
  EMAIL_TEMPLATE_FILTER_HANDLING,
  EMAIL_TEMPLATE_SEARCH_NAME,
  EMAIL_TEMPLATE_ACTION_SUCCESS,
  EMAIL_TEMPLATE_CATEGARY
} from '../actionTypes/emailTemplate';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from '../actionTypes/common';

import { authMemberSignOut } from './auth';
import axiosHttp from '../services/axiosCommon';

export const getTemplateCategory = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/email-template/categary', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGARY,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const getTemplateList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/email-template', {
      params: queryInfo
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      if (data.status === 200) {
        dispatch({
          type: EMAIL_TEMPLATE_LIST_SUCCESS,
          payload: { ...data, queryInfo }
        });
      }
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/email-template/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: EMAIL_TEMPLATE_LIST_SUCCESS,
        payload: { ...data, queryInfo }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const getTemplateInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.get('/email-template', {
      params: paramsObj
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: EMAIL_TEMPLATE_INFO_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const createTemplate =
  (infoData, templateType, templateSubType, associateId) => async (dispatch) => {
    dispatch({
      type: ON_SHOW_LOADER
    });
    try {
      const result = await axiosHttp.post(
        `/email-template/${templateType}/${templateSubType}/${associateId}`,
        infoData
      );
      const { data } = result;
      dispatch({
        type: ON_HIDE_LOADER
      });
      if (data.error) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: '/settings/email-template/list'
        });
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
        dispatch({
          type: EMAIL_TEMPLATE_CREATE_SUCCESS,
          payload: data
        });
      }

      return Promise.resolve(data);
    } catch (err) {
      const { response } = err;
      if (response && response.status === 401) {
        dispatch(
          authMemberSignOut({
            changeMessage: true,
            payload: {
              memberMessage: response.data.memberMessage,
              status: response.status,
              error: true
            }
          })
        );
      } else {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
        });
      }
      return Promise.reject(err);
    }
  };
export const updateTemplateWithFile =
  (infoData, templateType, templateSubType, associateId) => async (dispatch) => {
    dispatch({
      type: ON_SHOW_LOADER
    });
    console.log({ infoData }, '------------');
    try {
      const result = await axiosHttp.put(
        `/email-template/${templateType}/${templateSubType}/${associateId}`,
        infoData
      );
      const { data } = result;
      dispatch({
        type: ON_HIDE_LOADER
      });
      if (data.error) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: '/settings/email-template/list'
        });
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data
        });
        dispatch({
          type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
          payload: data
        });
      }

      return Promise.resolve(data);
    } catch (err) {
      const { response } = err;
      if (response && response.status === 401) {
        dispatch(
          authMemberSignOut({
            changeMessage: true,
            payload: {
              memberMessage: response.data.memberMessage,
              status: response.status,
              error: true
            }
          })
        );
      } else {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
        });
      }
      return Promise.reject(err);
    }
  };

export const updateTemplate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  console.log({ infoData }, '------------');
  try {
    const result = await axiosHttp.put(`/email-template`, infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/email-template/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const actionTemplate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  const { queryInfo } = infoData;
  try {
    const result = await axiosHttp.put('/email-template/action', {
      ...infoData,
      queryInfo: undefined
    });
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/email-template/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch(getTemplateList({ ...queryInfo }));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
export const deleteTemplate = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER
  });
  try {
    const result = await axiosHttp.delete('/email-template', infoData);
    const { data } = result;
    dispatch({
      type: ON_HIDE_LOADER
    });
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: '/settings/email-template/list'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: EMAIL_TEMPLATE_DELETE_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: EMAIL_TEMPLATE_SEARCH_NAME,
    payload: info
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: EMAIL_TEMPLATE_FILTER_HANDLING,
    payload: info
  });
};
