import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, Toolbar, OutlinedInput, InputAdornment } from '@material-ui/core';

// ----------------------------------------------------------------------
import { FilterSidebar } from '.';
import { getContactList, onChangeSearchName } from '../../appRedux/actions/contactDetails';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey['500_32']} !important`
  }
}));

// ----------------------------------------------------------------------

export default function ListToolbar() {
  const dispatch = useDispatch();
  const { contactId } = useParams();
  const { searchName, queryInfo, searchStatus } = useSelector((state) => ({
    searchName: state.contactDetails.searchName,
    searchStatus: state.contactDetails.searchStatus,
    queryInfo: state.contactDetails.queryInfo
  }));
  useEffect(() => {
    if (searchStatus) {
      const timeout = setTimeout(() => {
        dispatch(
          getContactList({
            ...queryInfo,
            contactId,
            searchText: searchName !== '' ? searchName : undefined
          })
        );
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchName, searchStatus]);

  const [openFilter, setOpenFilter] = useState(false);
  return (
    <>
      <RootStyle>
        <SearchStyle
          value={searchName}
          onChange={(event) => dispatch(onChangeSearchName(event.target.value))}
          placeholder="Search Contact..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
        <Button
          disableRipple
          color="inherit"
          endIcon={<Icon icon={roundFilterList} />}
          onClick={() => setOpenFilter(true)}
        >
          Filters&nbsp;
        </Button>
      </RootStyle>
      <FilterSidebar isOpenFilter={openFilter} onCloseFilter={() => setOpenFilter(false)} />
    </>
  );
}
