import * as React from 'react';
import {
  Box,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
  Button
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Icon } from '@iconify/react';
import DeleteFill from '@iconify/icons-ic/delete';
import plusFill from '@iconify/icons-eva/plus-fill';

import { experimentalStyled as styled } from '@material-ui/core/styles';

const StyleH4 = styled('h4')(({ theme }) => ({
  padding: theme.spacing(0, 0, 3, 0)
}));
export default function VloggingPlatform({ formik, userList, deleted, viewOnly }) {
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  console.log({ userList });
  const handleNewField = () => {
    formik.setFieldValue('platformList', [
      ...formik.values.platformList,
      { id: uuid(), type: '', subType: '', url: '', active: true }
    ]);
  };

  const handleRemoveField = (id) => {
    if (values.platformList.length >= 1) {
      formik.setFieldValue(
        'platformList',
        formik.values.platformList.filter((contact) => contact.id !== id)
      );
    }
  };

  values.platformList.map((info, index) => {
    console.log('values', values.platformList && values.platformList[index].subType);
    return info;
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={10} md={10}>
          <StyleH4>{viewOnly ? 'Channel Link' : 'Add Channel Link'} </StyleH4>
        </Grid>
        {!viewOnly && (
          <Grid item xs={12} sm={2} md={2}>
            {/* <Button /> */}
            <Button
              variant="contained"
              onClick={() => handleNewField()}
              startIcon={<Icon icon={plusFill} />}
            >
              New
            </Button>
          </Grid>
        )}
      </Grid>
      {values.platformList.map((contact, index) => (
        <Grid container spacing={3} m={0} key={contact.id}>
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Platform Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Contact Type"
                // {...getFieldProps('contactName')}
                {...getFieldProps(`platformList[${index}].subType`)}
                error={
                  touched.platformList &&
                  touched.platformList[index] &&
                  touched.platformList[index].subType &&
                  errors.platformList &&
                  errors.platformList[index] &&
                  Boolean(errors.platformList[index].subType)
                }
                helperText={
                  touched.platformList &&
                  touched.platformList[index] &&
                  touched.platformList[index].subType &&
                  errors.platformList &&
                  errors.platformList[index] &&
                  Boolean(errors.platformList[index].subType)
                }
                // error={Boolean(touched.whatsAppNumber && errors.whatsAppNumber)}
                // helperText={touched.whatsAppNumber && errors.whatsAppNumber}
                disabled={!contact.active || viewOnly}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {userList.map((list) => (
                  <MenuItem key={list.title} value={list.title}>
                    {list.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                error={
                  touched.platformList &&
                  touched.platformList[index] &&
                  touched.platformList[index].subType &&
                  errors.platformList &&
                  errors.platformList[index] &&
                  Boolean(errors.platformList[index].subType)
                }
              >
                {touched.platformList &&
                  touched.platformList[index] &&
                  touched.platformList[index].subType &&
                  errors.platformList &&
                  errors.platformList[index] &&
                  errors.platformList[index].subType}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={7} md={8}>
            <TextField
              fullWidth
              label={contact.subType !== '' ? `${contact.subType} URL Address` : 'URL Address'}
              {...getFieldProps(`platformList[${index}].url`)}
              error={
                touched.platformList &&
                touched.platformList[index] &&
                touched.platformList[index].url &&
                errors.platformList &&
                errors.platformList[index] &&
                Boolean(errors.platformList[index].url)
              }
              helperText={
                touched.platformList &&
                touched.platformList[index] &&
                touched.platformList[index].url &&
                errors.platformList &&
                errors.platformList[index] &&
                Boolean(errors.platformList[index].url)
              }
              disabled={!contact.active || viewOnly}
            />
            <FormHelperText
              error={
                touched.platformList &&
                touched.platformList[index] &&
                touched.platformList[index].url &&
                errors.platformList &&
                errors.platformList[index] &&
                Boolean(errors.platformList[index].url)
              }
            >
              {touched.platformList &&
                touched.platformList[index] &&
                touched.platformList[index].url &&
                errors.platformList &&
                errors.platformList[index] &&
                errors.platformList[index].url}
            </FormHelperText>
          </Grid>

          <Grid item xs={2} sm={1} md={1}>
            {deleted && !viewOnly && (
              <IconButton
                aria-label="fingerprint"
                color="error"
                onClick={() => handleRemoveField(contact.id)}
              >
                <Icon icon={DeleteFill} height={28} />{' '}
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
}
