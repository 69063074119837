import { format, formatDistanceToNow } from 'date-fns';

import moment from 'moment';
// ----------------------------------------------------------------------

export function mDate(date) {
  return moment(new Date(date)).format('Do MMM yyyy');
}
export function mTime(date) {
  return moment(new Date(date)).format('h:mm:ss A');
}
export function fDate(date) {
  return format(new Date(date), 'MMMM dd, yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fExpiredAt(date) {
  const date2 = moment(new Date());
  const date1 = moment(new Date(date));

  const days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  const hours = date1.diff(date2, 'hours');
  date2.add(hours, 'hours');

  const minutes = date1.diff(date2, 'minutes');
  date2.add(minutes, 'minutes');

  const seconds = date1.diff(date2, 'seconds');
  let msg = '';
  let status = 'green';
  if (hours > 2) {
    msg =
      hours + (hours > 1 ? ' hours ' : ' hour ') + minutes + (minutes > 1 ? ' minutes' : ' minute');
    status = 'success';
  } else if (hours > 0) {
    msg =
      hours + (hours > 1 ? ' hours ' : ' hour ') + minutes + (minutes > 1 ? ' minutes' : ' minute');
    status = 'warning';
  } else if (minutes > 0) {
    msg = minutes + (minutes > 1 ? ' minutes' : ' minute');
    status = 'warning';
  } else if (seconds > 0) {
    msg = `Expire in  ${seconds} ${seconds > 1 ? ' seconds' : ' second'}`;
    status = 'warning';
  } else {
    msg = 'Expired';
    status = 'error';
  }

  return { msg, status };
  // return { days, hours, minutes, seconds };
}

export function diffYMDHMS(date) {
  const date2 = moment(new Date());
  const date1 = moment(new Date(date));
  const years = date1.diff(date2, 'year');
  date2.add(years, 'years');

  const months = date1.diff(date2, 'months');
  date2.add(months, 'months');

  const days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  const hours = date1.diff(date2, 'hours');
  date2.add(hours, 'hours');

  const minutes = date1.diff(date2, 'minutes');
  date2.add(minutes, 'minutes');

  const seconds = date1.diff(date2, 'seconds');

  // //console.log(
  //   years +
  //     " years " +
  //     months +
  //     " months " +
  //     days +
  //     " days " +
  //     hours +
  //     "hours " +
  //     minutes +
  //     " minutes " +
  //     seconds +
  //     " seconds"
  // );

  return { days, hours, minutes, seconds };
}
