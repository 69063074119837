import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import { sentenceCase } from 'change-case';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  Box,
  TableHead,
  IconButton
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowDownFill from '@iconify/icons-eva/arrow-down-fill';
import arrowUpFill from '@iconify/icons-eva/arrow-up-fill';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import ResultNotFound from '../ResultNotFound';
import { ListHead, MoreMenu } from '.';
//
import { fDate, fExpiredAt, mDate, mTime } from '../../utils/formatTime';
import { bytesToSize } from '../../utils/fileConstant';

// ----------------------------------------------------------------------

function Row(props) {
  const { row, columnHandling, queryInfo } = props;
  const { _id, subject, contactsInfo, totalSize, expireAt, receivedTransfer, status } = row;
  const [open, setOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [transferReceived, setTransferReceived] = useState([]);

  const downloaded =
    receivedTransfer && receivedTransfer.length > 0
      ? receivedTransfer.reduce((downloaded, obj) => {
          return downloaded + obj.downloaded;
        }, 0)
      : 0;
  const diffTime = fExpiredAt(expireAt);
  return (
    <>
      <TableRow key={`${_id} _TableRow_0`}>
        {columnHandling.map((col, index) => {
          const { id, key, childKey, align } = col;

          if (key === 'sendTo') {
            return (
              <TableCell key={key} id="buttonTable" sx={{ padding: '6px 16px' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                    setContactInfo(contactsInfo);
                    setTransferReceived(receivedTransfer);
                  }}
                  // className="text-black  "
                >
                  {open ? (
                    <Icon icon={arrowUpFill} />
                  ) : (
                    <Icon icon={arrowDownFill} />
                    // <InlineIcon name={'expand_more'} size="2xl" color={'black'} />
                  )}
                </IconButton>
              </TableCell>
            );
          }
          if (key === 'subject') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                {subject || '...'}
              </TableCell>
            );
          }
          if (key === 'downloaded') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                {downloaded}
              </TableCell>
            );
          }
          if (key === 'totalSize') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                {bytesToSize(totalSize)}
              </TableCell>
            );
          }
          if (key === 'createdAt') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                <p className="text-sm ">{mTime(row[key])}</p>
                <span className="text-[12px] font-bold">{mDate(row[key])}</span>
                {/* <p className={`text-sm `}>{diffYMDHMS(row[key])}</p> */}
              </TableCell>
            );
          }
          if (key === 'expireAt' && status !== 'Banned') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                {status === 'Process' ? (
                  <Label color={'primary'}>{'Processing'}</Label>
                ) : (
                  <Label color={diffTime.status}>{diffTime.msg}</Label>
                )}
              </TableCell>
            );
          }
          if (key === 'expireAt' && status === 'Banned') {
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                <Label color="default" sx={{ color: '#3d0202', backgroundColor: '#625e5e54' }}>
                  Banned
                </Label>
              </TableCell>
            );
          }

          if (key === '_id') {
            return (
              <TableCell key={key} align={'center'} id="buttonTable2" sx={{ padding: '6px 16px' }}>
                {/* status === "Process" */}

                {/* <ListActionView {...{ diffTime, transferId: _id, queryInfo, status }} /> */}

                {/* */}
              </TableCell>
            );
          }
          if (key === 'active') {
            return (
              <TableCell key={key} align="center" sx={{ padding: '6px 16px' }}>
                <Label variant="ghost" color={(row[key] === false && 'error') || 'success'}>
                  {row[key] === false ? 'Banned' : 'Active'}
                </Label>
              </TableCell>
            );
          }
          if (key === 'createdAt') {
            // return <TableCell align={align}>{row[key]}</TableCell>;
            return (
              <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
                {fDate(row[key])}
              </TableCell>
            );
          }
          return key === 'action' ? (
            <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
              <MoreMenu transferId={_id} index={index} status={status} queryInfo={queryInfo} />
            </TableCell>
          ) : (
            <TableCell key={key} align={align} sx={{ padding: '6px 16px' }}>
              {row[key]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow key={`${_id} _TableRow_1`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit key={`${_id} _Collapse`}>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Sent To
              </Typography>
              {/* <InnerList contactInfo={contactInfo} /> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>Email</TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      Name
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      Company Name
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      Downloaded
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      Have Seen
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={`${_id} _TableBody`}>
                  {contactInfo.map((contact) => {
                    const received =
                      transferReceived.filter((obj) => obj.contactId === contact._id).pop() || {};
                    return (
                      <TableRow key={contact._id} sx={{ borderBottom: 'none' }}>
                        <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
                          {contact.email}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: 'none' }}>
                          {contact.name || '...'}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: 'none' }}>
                          {contact.companyName || '...'}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: 'none' }}>
                          {received.downloaded || 0}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: 'none' }}>
                          <Label variant="ghost" color={received.readStatus ? 'success' : 'error'}>
                            {received.readStatus ? 'Yes' : 'No'}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ListView() {
  const dispatch = useDispatch();

  const {
    transferType,
    searchStatus,
    searchName,
    transferList,
    queryInfo,
    page,
    limit,
    columnHandling,
    loader
  } = useSelector((state) => ({
    transferType: state.transferHistory.transferType,
    searchStatus: state.transferHistory.searchStatus,
    searchName: state.transferHistory.searchName,
    transferList: state.transferHistory.transferList,
    queryInfo: state.transferHistory.queryInfo,
    page: state.transferHistory.queryInfo.page,
    limit: state.transferHistory.queryInfo.limit,
    columnHandling: state.transferHistory.columnHandling.filter(
      (item) => item.visibility || !item.isFilter
    ),
    loader: state.common.loader
  }));
  const [open, setOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [transferReceived, setTransferReceived] = useState([]);

  const emptyRows = page > 0 ? Math.max(0, limit - transferList.length) : 0;
  console.log({ emptyRows, emptyRows2: limit, length: transferList.length });
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ListHead headLabel={columnHandling} checkbox={false} isSortLabel={false} />
          <TableBody>
            {transferList &&
              transferList.length > 0 &&
              transferList.map((row) => (
                <Row
                  key={`${row._id} _TableRow_9`}
                  row={row}
                  columnHandling={columnHandling}
                  queryInfo={queryInfo}
                />
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {transferList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  {searchStatus ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={`${transferType} Transfer`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
