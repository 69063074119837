import {
  SIGNOUT_AUTH_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  CODE_VERIFY,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL
} from '../actionTypes/auth';
import { ON_SHOW_MESSAGE, INIT_URL, ON_HIDE_MESSAGE, ON_HIDE_LOADER } from '../actionTypes/common';
// import AllDataService from '../services/all.service';
import axiosHttp from '../services/axiosCommon';

export const authLevelChannge = (info) => async (dispatch) => {
  dispatch({
    type: AUTH_LEVEL,
    payload: info
  });
};
export const authMemberSignIn = (authMember, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/login' : '/login';
  // const url = '/user/login';
  try {
    console.log({ axiosHttp });
    const result = await axiosHttp.post(url, authMember);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      localStorage.setItem('accessToken', data.authToken);
      localStorage.setItem('authMember', JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: '/dashboard/app'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    console.log({ err, response }, 'catch');
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
    });
    return Promise.reject(err);
  }
};

export const getTempToken = (tempToken, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/temp/token' : '/temp/token';
  // const url = '/user/temp/token';
  try {
    const result = await axiosHttp.get(url, {
      params: { tempToken }
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      localStorage.setItem('accessToken', data.authToken);
      localStorage.setItem('authMember', JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: '/dashboard/app'
      });
      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: { ...data.data }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data }
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          authMemberMessage: 'Some error has occurred',
          status: 500,
          error: true
        }
      });
    }
    return Promise.reject(err);
  }
};
export const setNewPassword = (authMember, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/set/new-password' : '/set/new-password';
  // const url = '/user/set/new-password';
  try {
    const result = await axiosHttp.post(url, authMember);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      localStorage.setItem('accessToken', data.authToken);
      localStorage.setItem('authMember', JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: '/dashboard/app'
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data }
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          authMemberMessage: 'Some error has occurred',
          status: 500,
          error: true
        }
      });
    }
    return Promise.reject(err);
  }
};

export const codeSend = (info, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/code/send' : '/code/send';
  // const url = '/user/code/send';
  console.log({ info });
  try {
    const result = await axiosHttp.post(url, info);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
      dispatch({
        type: INIT_URL,
        payload: '/verify-code'
      });
      dispatch({
        type: ON_HIDE_LOADER
      });

      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: { ...data.data }
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data }
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          authMemberMessage: 'Some error has occurred',
          status: 500,
          error: true
        }
      });
    }
    return Promise.reject(err);
  }
};
export const codeVerify = (info, type) => async (dispatch) => {
  const url = type === 'user' ? '/user/code/verify' : '/code/verify';
  // const url = '/user/code/verify';
  try {
    const result = await axiosHttp.post(url, info);
    const { data } = result;
    console.log({ payload: data }, '----result----');
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else if (data.type === 'SignUp') {
      console.log({ payload: data }, '----SignUp----');
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });

      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data
      });
    } else {
      console.log({ payload: data }, '--------');
      dispatch({
        type: ON_HIDE_LOADER
      });
      dispatch({
        type: CODE_VERIFY,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data }
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          authMemberMessage: 'Some error has occurred',
          status: 500,
          error: true
        }
      });
    }
    return Promise.reject(err);
  }
};
export const authMemberSignOut = (info) => async (dispatch) => {
  console.log('authMemberSignOut--------');
  dispatch({
    type: INIT_URL,
    payload: '/login'
  });

  dispatch({
    type: SIGNOUT_AUTH_USER_SUCCESS
  });
  localStorage.clear();
  if (info && info.changeMessage === true) {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: info.payload
    });
  } else {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: { memberMessage: 'Logout successfully', status: 200, error: true }
    });
  }
  const timeout = setTimeout(() => {
    dispatch({
      type: ON_HIDE_MESSAGE
    });
    dispatch({
      type: ALL_CLEAR_SUCCESS
    });
  }, 3000);
  return () => {
    clearTimeout(timeout);
  };

  // try {
  //   const result = await axiosHttp.post('/logout', {});
  //   const { data } = result;
  // dispatch({
  //   type: INIT_URL,
  //   payload: "/login"
  // });
  // dispatch({
  //   type: ON_SHOW_MESSAGE,
  //   payload: { memberMessage: 'Logout successfully', status: 200, error: false }
  // });
  // dispatch({
  //   type: SIGNOUT_AUTH_USER_SUCCESS
  // });
  // if (data.error) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  // } else {
  //   localStorage.setItem('accessToken', data.authToken);
  //   localStorage.setItem('authMember', JSON.stringify(data.data));
  //   dispatch({
  //     type: INIT_URL,
  //     payload: "/login"
  //   });
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  //   dispatch({
  //     type: SIGNIN_USER_SUCCESS,
  //     payload: data
  //   });
  // }

  //   return Promise.resolve(data);
  // } catch (err) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: { memberMessage: 'Request failed', status: 500, error: true }
  //   });
  //   return Promise.reject(err);
  // }
};
