import { DASHBOARD_INFO } from '../actionTypes/dashboard';
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER } from '../actionTypes/common';
import { authMemberSignOut } from './auth';
// import AllDataService from '../services/all.service';

import axiosHttp from '../services/axiosCommon';

export const getDashboardAnalytics = (paramsObj) => async (dispatch) => {
  // const url = type === 'user' ? '/user/dashboard/analytics' : '/dashboard/analytics';
  const url = '/dashboard/analytics';
  try {
    const result = await axiosHttp.get(url, {
      params: paramsObj
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data
      });
    } else {
      dispatch({
        type: DASHBOARD_INFO,
        payload: data
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        authMemberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true
          }
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { memberMessage: 'Some error has occurred', status: 500, error: true }
      });
    }
    return Promise.reject(err);
  }
};
