import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: 'auto',
    textAling: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  const { loader } = useSelector((state) => ({
    open: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
    loader: state.common.loader
  }));
  const onCloseFilter = () => {};
  return (
    <div className={classes.root}>
      <div style={{ zIndex: '1300' }}>
        <Backdrop
          className={classes.backdrop}
          open={loader}
          onClose={onCloseFilter}
          PaperProps={{
            sx: { width: '100%', border: 'none', overflow: 'hidden', zIndex: 1300 }
          }}
        >
          <CircularProgress
            color="primary"
            size={60}
            PaperProps={{
              sx: { width: '100%', border: 'none', overflow: 'hidden' }
            }}
          />
        </Backdrop>
      </div>
    </div>
  );
}
