import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Card, Stack, Container, Typography, Grid } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { FormView } from '../../components/_contact';
import { getContactInfo } from '../../appRedux/actions/contact';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3)
}));
const StyleText = styled('p')(({ theme }) => ({
  margin: '0px auto 16px',
  fontWeight: 400,
  fontSize: '0.9rem',
  lineHeight: 1.5,
  color: theme.palette.grey[500]
}));
// ----------------------------------------------------------------------

export default function ContactUpdate() {
  const dispatch = useDispatch();
  const params = useParams();
  console.log({ params });
  const { contactId } = params;
  useEffect(() => {
    dispatch(getContactInfo({ contactId }));
  }, [contactId]);
  return (
    <Page title="Update Contact | GGPL Play">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Update Contact
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <CardStyle>
              <h4>Update Contact Information</h4>
              <StyleText />

              <FormView {...{ isUpdate: true }} />
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
