import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer
} from '@material-ui/core';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import ResultNotFound from '../ResultNotFound';
import { ListHead, MoreMenu } from '.';
//
import { fDate } from '../../utils/formatTime';
import { bytesToSize } from '../../utils/fileConstant';

// ----------------------------------------------------------------------

export default function ListView() {
  const dispatch = useDispatch();

  const {
    userType,
    searchStatus,
    searchName,
    userList,
    queryInfo,
    page,
    limit,
    columnHandling,
    loader
  } = useSelector((state) => ({
    userType: state.user.userType,
    searchStatus: state.user.searchStatus,
    searchName: state.user.searchName,
    userList: state.user.userList,
    queryInfo: state.user.queryInfo,
    page: state.user.queryInfo.page,
    limit: state.user.queryInfo.limit,
    columnHandling: state.user.columnHandling.filter((item) => item.visibility || !item.isFilter),
    loader: state.common.loader
  }));

  const emptyRows = page > 0 ? Math.max(0, limit - userList.length) : 0;
  console.log({ emptyRows, emptyRows2: limit, length: userList.length });
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ListHead headLabel={columnHandling} checkbox={false} isSortLabel={false} />
          <TableBody>
            {userList &&
              userList.length > 0 &&
              userList.map((row) => {
                const { _id, active, name, profileDpUrl } = row;
                return (
                  <TableRow hover key={_id}>
                    {columnHandling.map((col, index) => {
                      const { id, key, align, childKey, childKeyOne, childKeyTwo } = col;

                      if (key === 'name') {
                        return (
                          <TableCell component="th" scope="row" padding="0 2" spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={profileDpUrl ? profileDpUrl : name} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                                {/* <Link to={`/user/${_id}/info`}>{name}</Link> */}
                              </Typography>
                            </Stack>
                          </TableCell>
                        );
                      }

                      if (key === 'storagePlanInfo' && childKey === 'accessData') {
                        return (
                          <TableCell key={index} align={align}>
                            <Label variant="ghost" color={'success'}>
                              {`${row[key][childKey].limit} ${row[key][childKey].limitIn}`}
                            </Label>
                          </TableCell>
                        );
                      }
                      if (key === 'storagePlanInfo' && childKey === 'expiration') {
                        return (
                          <TableCell key={index} align={align}>
                            <Label
                              variant="ghost"
                              color={'error'}
                              // color={(row[key] === false && 'error') || 'success'}
                            >
                              {`${row[key][childKey].duration} ${row[key][childKey].durationIn}`}
                            </Label>
                          </TableCell>
                        );
                      }
                      //
                      if (key === 'storagePlanInfo' && childKey === 'name') {
                        return (
                          <TableCell key={index} align={align}>
                            {`${row[key][childKey]}  `}
                          </TableCell>
                        );
                      }
                      if (key === 'active') {
                        return (
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(row[key] === false && 'error') || 'success'}
                            >
                              {row[key] === false ? 'Banned' : 'Active'}
                            </Label>
                          </TableCell>
                        );
                      }
                      //
                      if (key === 'usedStorage') {
                        // return <TableCell align={align}>{row[key]}</TableCell>;
                        return (
                          <TableCell align={align}>
                            <Label variant="ghost" color={'info'}>
                              {bytesToSize(row[key])}
                            </Label>
                          </TableCell>
                        );
                        //  <TableCell align={align}>{bytesToSize(row[key])}</TableCell>;
                      }
                      if (key === 'createdAt') {
                        // return <TableCell align={align}>{row[key]}</TableCell>;
                        return <TableCell align={align}>{fDate(row[key])}</TableCell>;
                      }
                      return key === 'action' ? (
                        <TableCell align={align}>
                          <MoreMenu
                            active={active}
                            userId={_id}
                            index={index}
                            queryInfo={queryInfo}
                          />
                        </TableCell>
                      ) : (
                        <TableCell align={align}>{row[key]}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {userList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  {searchStatus ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={`${userType} User`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
